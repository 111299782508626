import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Stepper,
  Step,
  StepLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import DiamondIcon from '@mui/icons-material/Diamond';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background: linear-gradient(135deg, #18181B 0%, #27272A 100%);
    border-radius: 20px;
    border: 1px solid rgba(139, 92, 246, 0.2);
    max-width: 600px;
    width: 100%;
  }
`;

const StyledTextField = styled(TextField)`
  margin: 12px 0;
  
  .MuiOutlinedInput-root {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.primary.main};
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.primary.main};
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  margin: 12px 0;
  
  .MuiOutlinedInput-root {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.primary.main};
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.primary.main};
    }
  }
`;

const ServiceCard = styled(Box)`
  padding: 20px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.05);
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    border-color: ${props => props.theme.palette.primary.main};
    transform: translateY(-4px);
  }

  ${props => props.selected && `
    border-color: ${props.theme.palette.primary.main};
    background: rgba(139, 92, 246, 0.1);
  `}
`;

const GradientButton = styled(Button)`
  background: linear-gradient(45deg, ${props => props.theme.palette.primary.main}, ${props => props.theme.palette.primary.light});
  color: white;
  padding: 12px 24px;
  font-size: 1rem;
  border-radius: 12px;
  text-transform: none;
  transition: all 0.3s ease;
  
  &:hover {
    background: linear-gradient(45deg, ${props => props.theme.palette.primary.light}, ${props => props.theme.palette.primary.main});
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(139, 92, 246, 0.3);
  }
`;

const services = [
  {
    id: 'eloboost',
    title: 'Elo Boost',
    description: 'Alcance seu elo desejado com nossos boosters profissionais',
    icon: <RocketLaunchIcon sx={{ fontSize: 40 }} />,
  },
  {
    id: 'duoboost',
    title: 'Duo Boost',
    description: 'Jogue em duo com um booster profissional',
    icon: <DiamondIcon sx={{ fontSize: 40 }} />,
  },
  {
    id: 'md10',
    title: 'MD5',
    description: 'Maximize seu elo inicial nas partidas de MD5',
    icon: <EmojiEventsIcon sx={{ fontSize: 40 }} />,
  },
];

const steps = ['Escolha o Serviço', 'Detalhes', 'Contato'];

const ServiceRequestModal = ({ open, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedService, setSelectedService] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    currentRank: '',
    desiredRank: '',
    server: 'br',
    preferredTime: 'any',
  });

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = () => {
    const message = `Olá! Gostaria de solicitar um serviço de ${selectedService}
Nome: ${formData.name}
Email: ${formData.email}
Telefone: ${formData.phone}
Elo Atual: ${formData.currentRank}
Elo Desejado: ${formData.desiredRank}
Servidor: ${formData.server}
Horário Preferido: ${formData.preferredTime}`;

    const whatsappUrl = `https://wa.me/5515991909782?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
    onClose();
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Stack spacing={2}>
            {services.map((service) => (
              <ServiceCard
                key={service.id}
                selected={selectedService === service.id}
                onClick={() => setSelectedService(service.id)}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  {service.icon}
                  <Box>
                    <Typography variant="h6">{service.title}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {service.description}
                    </Typography>
                  </Box>
                </Stack>
              </ServiceCard>
            ))}
          </Stack>
        );
      case 1:
        return (
          <Stack spacing={2}>
            <StyledFormControl fullWidth>
              <InputLabel>Elo Atual</InputLabel>
              <Select
                value={formData.currentRank}
                onChange={(e) => setFormData({ ...formData, currentRank: e.target.value })}
                label="Elo Atual"
              >
                <MenuItem value="ferro">Ferro</MenuItem>
                <MenuItem value="bronze">Bronze</MenuItem>
                <MenuItem value="prata">Prata</MenuItem>
                <MenuItem value="ouro">Ouro</MenuItem>
                <MenuItem value="platina">Platina</MenuItem>
                <MenuItem value="diamante">Diamante</MenuItem>
              </Select>
            </StyledFormControl>

            <StyledFormControl fullWidth>
              <InputLabel>Elo Desejado</InputLabel>
              <Select
                value={formData.desiredRank}
                onChange={(e) => setFormData({ ...formData, desiredRank: e.target.value })}
                label="Elo Desejado"
              >
                <MenuItem value="bronze">Bronze</MenuItem>
                <MenuItem value="prata">Prata</MenuItem>
                <MenuItem value="ouro">Ouro</MenuItem>
                <MenuItem value="platina">Platina</MenuItem>
                <MenuItem value="diamante">Diamante</MenuItem>
              </Select>
            </StyledFormControl>

            <StyledFormControl fullWidth>
              <InputLabel>Servidor</InputLabel>
              <Select
                value={formData.server}
                onChange={(e) => setFormData({ ...formData, server: e.target.value })}
                label="Servidor"
              >
                <MenuItem value="br">Brasil</MenuItem>
                <MenuItem value="na">Norte América</MenuItem>
                <MenuItem value="euw">Europa Oeste</MenuItem>
              </Select>
            </StyledFormControl>

            <FormControl component="fieldset">
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Horário Preferido
              </Typography>
              <RadioGroup
                value={formData.preferredTime}
                onChange={(e) => setFormData({ ...formData, preferredTime: e.target.value })}
              >
                <FormControlLabel
                  value="any"
                  control={<Radio />}
                  label="Qualquer horário"
                />
                <FormControlLabel
                  value="morning"
                  control={<Radio />}
                  label="Manhã (8h - 12h)"
                />
                <FormControlLabel
                  value="afternoon"
                  control={<Radio />}
                  label="Tarde (13h - 18h)"
                />
                <FormControlLabel
                  value="night"
                  control={<Radio />}
                  label="Noite (19h - 23h)"
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        );
      case 2:
        return (
          <Stack spacing={2}>
            <StyledTextField
              fullWidth
              label="Nome"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
            <StyledTextField
              fullWidth
              label="Email"
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
            <StyledTextField
              fullWidth
              label="Telefone"
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
            />
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Solicitar Serviço
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {renderStepContent(activeStep)}
      </DialogContent>

      <DialogActions sx={{ p: 3 }}>
        {activeStep > 0 && (
          <Button onClick={handleBack} sx={{ mr: 1 }}>
            Voltar
          </Button>
        )}
        {activeStep === steps.length - 1 ? (
          <GradientButton
            onClick={handleSubmit}
            startIcon={<WhatsAppIcon />}
          >
            Enviar no WhatsApp
          </GradientButton>
        ) : (
          <GradientButton onClick={handleNext}>
            Próximo
          </GradientButton>
        )}
      </DialogActions>
    </StyledDialog>
  );
};

export default ServiceRequestModal; 