import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Stack,
  Chip,
  Slider,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tabs,
  Tab,
  Checkbox,
  Divider,
} from '@mui/material';
import styled from 'styled-components';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import StarIcon from '@mui/icons-material/Star';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import DiamondIcon from '@mui/icons-material/Diamond';
import AddIcon from '@mui/icons-material/Add';

const PageWrapper = styled(Box)`
  background: linear-gradient(135deg, #09090B 0%, #1A1A1A 100%);
  min-height: 100vh;
  padding: 120px 0 80px;
  position: relative;
  overflow: hidden;
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.3;
  z-index: 0;
`;

const ContentWrapper = styled(Box)`
  position: relative;
  z-index: 1;
`;

const LolElement = styled.img`
  position: absolute;
  z-index: 0;
  opacity: 0.2;
  pointer-events: none;
`;

const TopLeftElement = styled(LolElement)`
  top: 50px;
  left: 50px;
  width: 200px;
  transform: rotate(-15deg);
`;

const BottomRightElement = styled(LolElement)`
  bottom: 50px;
  right: 50px;
  width: 250px;
  transform: rotate(15deg);
`;

const SuccessMessage = styled(Box)`
  position: fixed;
  top: 20px;
  right: 20px;
  background: rgba(139, 92, 246, 0.9);
  color: white;
  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: slideIn 0.3s ease;

  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;

const StyledCard = styled(Card)`
  background: linear-gradient(135deg, rgba(139, 92, 246, 0.1) 0%, rgba(139, 92, 246, 0.05) 100%);
  border: 1px solid rgba(139, 92, 246, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  height: 100%;
`;

const StyledSelect = styled(Select)`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.palette.primary.main};
  }
`;

const PriceDisplay = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(45deg, #fff, ${props => props.theme.palette.primary.light});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 16px 0;
`;

const StyledTab = styled(Tab)`
  color: ${props => props.theme.palette.text.primary};
  opacity: 0.7;
  
  &.Mui-selected {
    color: ${props => props.theme.palette.primary.main};
    opacity: 1;
  }
`;

const AddonCard = styled(Card)`
  background: rgba(139, 92, 246, 0.05);
  border: 1px solid rgba(139, 92, 246, 0.1);
  padding: 16px;
  margin: 8px 0;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: ${props => props.theme.palette.primary.main};
    background: rgba(139, 92, 246, 0.1);
  }
`;

const PDLSlider = styled(Slider)`
  color: ${props => props.theme.palette.primary.main};
  margin-top: 8px;
`;

const eloJobPrices = {
  'Ferro': 14.85,
  'Bronze': 18.05,
  'Prata': 23.50,
  'Ouro': 28.20,
  'Platina': 37.60,
  'Esmeralda': 47.00,
  'Diamante': 56.40,
  'Mestre': 168.00,
  'Grão Mestre': 987.00,
  'Challenger': 1786.00
};

const duoJobPrices = {
  'Ferro': 22.30,
  'Bronze': 27.10,
  'Prata': 35.25,
  'Ouro': 42.30,
  'Platina': 56.40,
  'Esmeralda': 70.50,
  'Diamante': 84.60,
  'Mestre': 252.00,
  'Grão Mestre': 1480.50,
  'Challenger': 2679.00
};

const MAIN_RANKS = [
  'Ferro',
  'Bronze',
  'Prata',
  'Ouro',
  'Platina',
  'Esmeralda',
  'Diamante',
  'Mestre'
];

const md5Prices = {
  'Ferro': { boost: 11, duo: 14 },
  'Bronze': { boost: 14, duo: 16 },
  'Prata': { boost: 16, duo: 20 },
  'Ouro': { boost: 21, duo: 26 },
  'Platina': { boost: 28, duo: 33 },
  'Esmeralda': { boost: 34, duo: 45 },
  'Diamante': { boost: 40, duo: 66 },
  'Mestre': { boost: 53, duo: 110 }
};

const victoryPrices = {
  'Ferro': { boost: 2, duo: 3 },
  'Bronze': { boost: 3, duo: 4 },
  'Prata': { boost: 5, duo: 7 },
  'Ouro': { boost: 5, duo: 7 },
  'Platina': { boost: 6, duo: 8 },
  'Esmeralda': { boost: 10, duo: 14 },
  'Diamante': { boost: 21, duo: 42 },
  'Mestre': { boost: 42, duo: 126 }
};

const promotionPrices = {
  'Ferro': { elojob: 50, duojob: 75 },
  'Bronze': { elojob: 75, duojob: 112.50 },
  'Prata': { elojob: 100, duojob: 150 },
  'Ouro': { elojob: 150, duojob: 225 },
  'Platina': { elojob: 300, duojob: 450 },
  'Esmeralda': { elojob: 11, duojob: 16.50 },
  'Diamante': { elojob: 400, duojob: 600 },
  'Mestre': { elojob: 600, duojob: 900 },
  'Grão Mestre': { elojob: 800, duojob: 1200 }
};

const divisionPrices = {
  'Ferro': { elojob: 5, duojob: 7.50 },
  'Bronze': { elojob: 7, duojob: 10.50 },
  'Prata': { elojob: 10, duojob: 15 },
  'Ouro': { elojob: 15, duojob: 22.50 },
  'Platina': { elojob: 20, duojob: 30 },
  'Esmeralda': { elojob: 18, duojob: 27 },
  'Diamante': { elojob: 10, duojob: 15 },
  'Mestre': { elojob: 50, duojob: 75 }
};

// Preços por divisão para cada tier
const DIVISION_PRICES = {
  ferro: 7,
  bronze: 8,
  prata: 12,
  ouro: 14,
  platina: 21,
  esmeralda: 41,
  diamante: {
    'iv_to_iii': 63,
    'iii_to_ii': 68,
    'ii_to_i': 74
  }
};

// Preços fixos para promoções especiais
const SPECIAL_PRICES = {
  diamante_to_mestre: 88,
  grao_mestre_to_challenger: 750
};

const TIERS = [
  'ferro',
  'bronze',
  'prata',
  'ouro',
  'platina',
  'esmeralda',
  'diamante',
  'mestre',
  'grão mestre',
  'challenger'
];

const RANKS = [
  'Ferro IV', 'Ferro III', 'Ferro II', 'Ferro I',
  'Bronze IV', 'Bronze III', 'Bronze II', 'Bronze I',
  'Prata IV', 'Prata III', 'Prata II', 'Prata I',
  'Ouro IV', 'Ouro III', 'Ouro II', 'Ouro I',
  'Platina IV', 'Platina III', 'Platina II', 'Platina I',
  'Esmeralda IV', 'Esmeralda III', 'Esmeralda II', 'Esmeralda I',
  'Diamante IV', 'Diamante III', 'Diamante II', 'Diamante I',
  'Mestre', 'Grão Mestre', 'Challenger'
];

const getEloInfo = (elo) => {
  if (!elo) return null;
  const [tier, divisionStr] = elo.toLowerCase().split(' ');
  const division = divisionStr ? 
    parseInt(divisionStr.replace('iv', '4').replace('iii', '3').replace('ii', '2').replace('i', '1')) : 
    null;
  return {
    tier,
    division,
    value: RANKS.indexOf(elo)
  };
};

const Pricing = () => {
  const [currentRank, setCurrentRank] = useState('');
  const [desiredRank, setDesiredRank] = useState('');
  const [pdl, setPdl] = useState([0, 20]);
  const [server, setServer] = useState('BR');
  const [pdlGain, setPdlGain] = useState('13-16');
  const [serviceType, setServiceType] = useState('elojob');
  const [addons, setAddons] = useState({
    champions: false,
    schedule: false,
    stream: false,
    express: false,
    extraWin: false,
    screenShare: false,
    monoChamp: false,
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [currentElo, setCurrentElo] = useState('');
  const [targetElo, setTargetElo] = useState('');
  const [gameMode, setGameMode] = useState('boost');
  const [victories, setVictories] = useState(1);

  const getEstimatedTime = () => {
    if (!currentRank || !desiredRank) return '0';
    const rankValues = {
      'Ferro IV': 0, 'Ferro III': 1, 'Ferro II': 2, 'Ferro I': 3,
      'Bronze IV': 4, 'Bronze III': 5, 'Bronze II': 6, 'Bronze I': 7,
      'Prata IV': 8, 'Prata III': 9, 'Prata II': 10, 'Prata I': 11,
      'Ouro IV': 12, 'Ouro III': 13, 'Ouro II': 14, 'Ouro I': 15,
      'Platina IV': 16, 'Platina III': 17, 'Platina II': 18, 'Platina I': 19,
      'Diamante IV': 20, 'Diamante III': 21, 'Diamante II': 22, 'Diamante I': 23,
    };

    const difference = rankValues[desiredRank] - rankValues[currentRank];
    const baseHours = difference * 2;
    return addons.express ? Math.round(baseHours * 0.7) : baseHours;
  };

  const calculatePrice = () => {
    if (!currentElo) return 0;
    
    // Para MD5, retorna diretamente o preço da tabela
    if (serviceType === 'md5') {
      const mode = gameMode === 'boost' ? 'boost' : 'duo';
      return md5Prices[currentElo]?.[mode] || 0;
    }
    
    // Para vitórias, calcula o preço baseado no número de vitórias
    if (serviceType === 'victories') {
      const mode = gameMode === 'boost' ? 'boost' : 'duo';
      const pricePerWin = victoryPrices[currentElo]?.[mode] || 0;
      return pricePerWin * victories;
    }
    
    // Se não for MD5 ou victories, precisa do elo alvo
    if (!targetElo) return 0;
    
    const currentEloInfo = getEloInfo(currentElo);
    const targetEloInfo = getEloInfo(targetElo);
    
    if (!currentEloInfo || !targetEloInfo) return 0;
    
    // Se o elo desejado for menor que o atual, retorna 0
    if (targetEloInfo.value <= currentEloInfo.value) return 0;
    
    let totalPrice = 0;
    let currentTier = currentEloInfo.tier;
    let currentDiv = currentEloInfo.division;
    
    // Tratamento especial para elos sem divisão (Mestre, Grão Mestre, Challenger)
    const elosSemDivisao = ['mestre', 'grão mestre', 'challenger'];
    
    while (currentTier !== targetEloInfo.tier || (currentDiv !== targetEloInfo.division && !elosSemDivisao.includes(targetEloInfo.tier))) {
      // Se estamos no tier Diamante
      if (currentTier === 'diamante') {
        if (currentDiv > 1) {
          const divisionKey = currentDiv === 4 ? 'iv_to_iii' : 
                            currentDiv === 3 ? 'iii_to_ii' : 
                            'ii_to_i';
          totalPrice += DIVISION_PRICES.diamante[divisionKey];
        }
        // Se o alvo é Mestre ou superior
        if (currentDiv === 1 && elosSemDivisao.includes(targetEloInfo.tier)) {
          totalPrice += SPECIAL_PRICES.diamante_to_mestre;
          currentTier = 'mestre';
          currentDiv = null;
          continue;
        }
      } 
      // Se estamos em Mestre e o alvo é Challenger
      else if (currentTier === 'mestre' && targetEloInfo.tier === 'challenger') {
        totalPrice += SPECIAL_PRICES.grao_mestre_to_challenger;
        break;
      }
      // Para todos os outros tiers com divisões
      else if (!elosSemDivisao.includes(currentTier)) {
        totalPrice += DIVISION_PRICES[currentTier];
      }
      
      // Atualiza a posição atual
      if (currentDiv > 1) {
        currentDiv--;
      } else {
        const nextTierIndex = TIERS.indexOf(currentTier) + 1;
        currentTier = TIERS[nextTierIndex];
        currentDiv = 4;
      }
    }
    
    // Aplica o multiplicador para Duojob
    if (serviceType === 'duojob') {
      totalPrice *= 1.5;
    }
    
    return Math.round(totalPrice);
  };

  // Função auxiliar para converter números em numerais romanos
  const toRomanNumeral = (num) => {
    const romanNumerals = ['I', 'II', 'III', 'IV'];
    return romanNumerals[num - 1] || '';
  };

  const handleWhatsApp = () => {
    const message = `Olá! Gostaria de contratar o serviço de ${serviceType.toUpperCase()}
Elo Atual: ${currentElo}
${serviceType === 'md5' || serviceType === 'victories' ? 
  `Modo: ${gameMode === 'boost' ? 'SOLO' : 'DUO'}${serviceType === 'victories' ? `\nNúmero de Vitórias: ${victories}` : ''}` : 
  `Elo Desejado: ${targetElo}`}
Valor: R$ ${calculatePrice()},00`;

    window.open(`https://wa.me/5515991909782?text=${encodeURIComponent(message)}`, '_blank');
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 3000);
  };

  return (
    <PageWrapper>
      <VideoBackground autoPlay muted loop playsInline>
        <source src="/src/video.webm" type="video/webm" />
      </VideoBackground>

      <TopLeftElement src="/images/lol-elements/yasuo.png" alt="Yasuo" />
      <BottomRightElement src="/images/lol-elements/jinx.png" alt="Jinx" />

      <ContentWrapper>
        <Container maxWidth="lg">
          <Typography variant="h2" align="center" gutterBottom sx={{ mb: 2 }}>
            Tabela de Preços
          </Typography>

          <Tabs
            value={serviceType}
            onChange={(_, newValue) => setServiceType(newValue)}
            centered
            sx={{ mb: 6 }}
          >
            <StyledTab value="elojob" label="Elo Job" />
            <StyledTab value="duojob" label="Duo Job" />
            <StyledTab value="md5" label="MD5" />
            <StyledTab value="victories" label="Vitórias" />
            <StyledTab value="coach" label="Coach" />
          </Tabs>

          <Grid container spacing={4}>
            <Grid item xs={12} md={7}>
              <StyledCard>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Tipo de Serviço
                  </Typography>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Serviço</InputLabel>
                    <Select
                      value={serviceType}
                      onChange={(e) => setServiceType(e.target.value)}
                      label="Serviço"
                    >
                      <MenuItem value="elojob">Elo Job</MenuItem>
                      <MenuItem value="duojob">Duo Job</MenuItem>
                      <MenuItem value="md5">MD5</MenuItem>
                      <MenuItem value="victories">Vitórias</MenuItem>
                      <MenuItem value="coach">Coach</MenuItem>
                    </Select>
                  </FormControl>

                  {serviceType !== 'coach' && (
                    <>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Elo Atual</InputLabel>
                        <Select
                          value={currentElo}
                          onChange={(e) => setCurrentElo(e.target.value)}
                          label="Elo Atual"
                        >
                          {(serviceType === 'md5' || serviceType === 'victories') ? 
                            MAIN_RANKS.map((rank) => (
                              <MenuItem key={rank} value={rank}>{rank}</MenuItem>
                            )) :
                            RANKS.map((rank) => (
                              <MenuItem key={rank} value={rank}>{rank}</MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>

                      {serviceType !== 'md5' && serviceType !== 'victories' && (
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>Elo Desejado</InputLabel>
                          <Select
                            value={targetElo}
                            onChange={(e) => setTargetElo(e.target.value)}
                            label="Elo Desejado"
                          >
                            {RANKS.map((rank) => (
                              <MenuItem key={rank} value={rank}>{rank}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}

                      {(serviceType === 'md5' || serviceType === 'victories') && (
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>Modo</InputLabel>
                          <Select
                            value={gameMode}
                            onChange={(e) => setGameMode(e.target.value)}
                            label="Modo"
                          >
                            <MenuItem value="boost">SOLO</MenuItem>
                            <MenuItem value="duo">DUO</MenuItem>
                          </Select>
                        </FormControl>
                      )}

                      {serviceType === 'victories' && (
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>Número de Vitórias</InputLabel>
                          <Select
                            value={victories}
                            onChange={(e) => setVictories(e.target.value)}
                            label="Número de Vitórias"
                          >
                            {[1,2,3,4,5,6,7,8,9,10].map(num => (
                              <MenuItem key={num} value={num}>{num} vitória{num > 1 ? 's' : ''}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </>
                  )}

                  {serviceType === 'victories' && currentElo && (
                    <Box sx={{ mt: 2, mb: 2 }}>
                      <Typography variant="body2" color="text.secondary">
                        Preço por vitória no {currentElo}: {gameMode === 'boost' ? 
                          `R$ ${victoryPrices[currentElo]?.boost},00 (SOLO)` : 
                          `R$ ${victoryPrices[currentElo]?.duo},00 (DUO)`}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Total para {victories} {victories === 1 ? 'vitória' : 'vitórias'}: R$ {calculatePrice()},00
                      </Typography>
                    </Box>
                  )}

                  <Box mt={4}>
                    <Typography variant="h6" gutterBottom>
                      Valor Total:
                    </Typography>
                    <PriceDisplay>
                      {serviceType === 'coach' ? 'R$ 45,00' : `R$ ${calculatePrice()},00`}
                    </PriceDisplay>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                      startIcon={<WhatsAppIcon />}
                      onClick={handleWhatsApp}
                    >
                      Contratar Agora
                    </Button>
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>

            <Grid item xs={12} md={5}>
              <StyledCard>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Informações Importantes
                  </Typography>
                  <Stack spacing={2}>
                    <Box>
                      <Typography variant="subtitle1" color="primary" gutterBottom>
                        Sobre MD5
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Para MD5, o preço é baseado no seu último elo da temporada anterior.
                        Selecione seu elo anterior para calcular o valor.
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="subtitle1" color="primary" gutterBottom>
                        Sobre Coach
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Pacote com 2 aulas por R$ 45,00. Aulas personalizadas para melhorar
                        seu gameplay com profissionais experientes.
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="subtitle1" color="primary" gutterBottom>
                        Vitórias Garantidas
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Escolha o número de vitórias que deseja e o modo (Solo ou Duo).
                        Preços variam de acordo com seu elo atual.
                      </Typography>
                    </Box>

                    <Divider />

                    <Box>
                      <Typography variant="subtitle1" color="primary" gutterBottom>
                        Garantias
                      </Typography>
                      <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
                        <Chip label="Entrega Rápida" />
                        <Chip label="Segurança Garantida" />
                        <Chip label="Suporte 24/7" />
                        <Chip label="Acompanhamento em Tempo Real" />
                      </Stack>
                    </Box>
                  </Stack>
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>
        </Container>
      </ContentWrapper>

      {showSuccess && (
        <SuccessMessage>
          <Typography variant="body1">
            Seu pedido foi registrado! Em breve entraremos em contato.
          </Typography>
        </SuccessMessage>
      )}
    </PageWrapper>
  );
};

export default Pricing; 