import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Avatar,
  LinearProgress,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from '@mui/material';
import styled from 'styled-components';
import { collection, query, where, getDocs, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import Sidebar from '../../components/Sidebar';

const DashboardRoot = styled(Box)`
  display: flex;
  min-height: 100vh;
  background: ${props => props.theme.palette.background.default};
`;

const DashboardContent = styled(Box)`
  flex-grow: 1;
  padding: 32px;
  margin-left: 280px;
  width: calc(100% - 280px);
`;

const StyledCard = styled(Card)`
  background: linear-gradient(135deg, rgba(139, 92, 246, 0.1) 0%, rgba(139, 92, 246, 0.05) 100%);
  border: 1px solid rgba(139, 92, 246, 0.2);
  border-radius: 16px;
  height: 100%;
`;

const ProfileCard = styled(StyledCard)`
  position: relative;
  overflow: visible;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: linear-gradient(135deg, rgba(139, 92, 246, 0.3) 0%, rgba(139, 92, 246, 0.1) 100%);
    border-radius: 16px 16px 0 0;
  }
`;

const LargeAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
  margin: 40px auto 16px;
  border: 4px solid rgba(139, 92, 246, 0.2);
  background-color: #8B5CF6;
`;

const ServiceStatus = styled(Chip)`
  background: ${props => props.status === 'completed' ? 'rgba(34, 197, 94, 0.1)' : 'rgba(139, 92, 246, 0.1)'};
  color: ${props => props.status === 'completed' ? '#22c55e' : '#8B5CF6'};
  border: 1px solid ${props => props.status === 'completed' ? 'rgba(34, 197, 94, 0.2)' : 'rgba(139, 92, 246, 0.2)'};
  font-weight: 500;
`;

const NotificationItem = styled(ListItem)`
  border-radius: 8px;
  margin-bottom: 8px;
  background: rgba(139, 92, 246, 0.05);
  border: 1px solid rgba(139, 92, 246, 0.1);

  &:hover {
    background: rgba(139, 92, 246, 0.1);
  }
`;

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [services, setServices] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) return;

        const userDoc = await getDocs(query(
          collection(db, 'users'),
          where('email', '==', currentUser.email)
        ));

        if (!userDoc.empty) {
          setUser({ id: userDoc.docs[0].id, ...userDoc.docs[0].data() });
        }
      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);
      }
    };

    const fetchServices = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) return;

        const servicesQuery = query(
          collection(db, 'services'),
          where('assignedTo.id', '==', currentUser.uid)
        );

        const servicesSnapshot = await getDocs(servicesQuery);
        const servicesData = servicesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setServices(servicesData);
      } catch (error) {
        console.error('Erro ao buscar serviços:', error);
      }
    };

    // Buscar notificações em tempo real
    const unsubscribe = onSnapshot(
      query(
        collection(db, 'notifications'),
        where('userId', '==', auth.currentUser?.uid)
      ),
      (snapshot) => {
        const notificationsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })).sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
        setNotifications(notificationsData);
      }
    );

    fetchUserData();
    fetchServices();
    setLoading(false);

    return () => unsubscribe();
  }, []);

  const formatDate = (date) => {
    return new Date(date.toDate()).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return <Typography>Carregando...</Typography>;
  }

  return (
    <DashboardRoot>
      <Sidebar />
      <DashboardContent>
        <Grid container spacing={3}>
          {/* Perfil do Usuário */}
          <Grid item xs={12} md={4}>
            <ProfileCard>
              <CardContent sx={{ textAlign: 'center', position: 'relative' }}>
                <LargeAvatar>
                  {user?.email?.charAt(0).toUpperCase()}
                </LargeAvatar>
                <Typography variant="h5" gutterBottom>
                  {user?.name || 'Usuário'}
                </Typography>
                
                <Box sx={{ mt: 2 }}>
                  <Box display="flex" alignItems="center" gap={1} mb={1}>
                    <EmailIcon color="primary" />
                    <Typography variant="body2">{user?.email}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    <PersonIcon color="primary" />
                    <Typography variant="body2">
                      Membro desde {user?.createdAt ? new Date(user.createdAt.toDate()).toLocaleDateString('pt-BR') : 'N/A'}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </ProfileCard>
          </Grid>

          {/* Serviços Atribuídos */}
          <Grid item xs={12} md={8}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Meus Serviços
                </Typography>
                
                {services.length === 0 ? (
                  <Typography color="textSecondary">
                    Nenhum serviço atribuído ainda.
                  </Typography>
                ) : (
                  services.map((service) => (
                    <Box
                      key={service.id}
                      sx={{
                        mb: 3,
                        p: 2,
                        borderRadius: 2,
                        background: 'rgba(139, 92, 246, 0.05)',
                        border: '1px solid rgba(139, 92, 246, 0.1)'
                      }}
                    >
                      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <Typography variant="subtitle1" fontWeight="500">
                          {service.name}
                        </Typography>
                        <ServiceStatus
                          label={service.status === 'completed' ? 'Concluído' : 'Em Andamento'}
                          status={service.status}
                          icon={service.status === 'completed' ? <CheckCircleIcon /> : <AccessTimeIcon />}
                        />
                      </Box>
                      
                      <Typography variant="body2" color="textSecondary" paragraph>
                        {service.description}
                      </Typography>
                      
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box>
                          <Typography variant="body2" color="primary">
                            Categoria: {service.category}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Duração: {service.duration} dias
                          </Typography>
                        </Box>
                        <Typography variant="h6" color="primary">
                          R$ {service.price}
                        </Typography>
                      </Box>

                      {service.status !== 'completed' && (
                        <Box mt={2}>
                          <Typography variant="body2" color="textSecondary" mb={1}>
                            Progresso: {service.progress || 0}%
                          </Typography>
                          <LinearProgress
                            variant="determinate"
                            value={service.progress || 0}
                            sx={{
                              height: 8,
                              borderRadius: 4,
                              backgroundColor: 'rgba(139, 92, 246, 0.1)',
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: '#8B5CF6'
                              }
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  ))
                )}
              </CardContent>
            </StyledCard>
          </Grid>

          {/* Avisos do Admin */}
          <Grid item xs={12}>
            <StyledCard>
              <CardContent>
                <Box display="flex" alignItems="center" gap={1} mb={3}>
                  <NotificationsIcon color="primary" />
                  <Typography variant="h6">
                    Avisos
                  </Typography>
                </Box>

                {notifications.length === 0 ? (
                  <Typography color="textSecondary">
                    Nenhum aviso recebido.
                  </Typography>
                ) : (
                  <List>
                    {notifications.map((notification, index) => (
                      <React.Fragment key={notification.id}>
                        <NotificationItem>
                          <ListItemIcon>
                            <AssignmentIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={notification.message}
                            secondary={formatDate(notification.createdAt)}
                          />
                        </NotificationItem>
                        {index < notifications.length - 1 && <Divider />}
                      </React.Fragment>
                    ))}
                  </List>
                )}
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </DashboardContent>
    </DashboardRoot>
  );
};

export default Dashboard; 