import React from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
} from '@mui/material';
import styled from 'styled-components';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SecurityIcon from '@mui/icons-material/Security';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PaymentsIcon from '@mui/icons-material/Payments';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const PageWrapper = styled(Box)`
  padding: 120px 0;
  background: ${props => props.theme.palette.background.default};
  min-height: 100vh;
`;

const StepCard = styled(Paper)`
  padding: 32px;
  background: linear-gradient(135deg, rgba(139, 92, 246, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
  border: 1px solid rgba(139, 92, 246, 0.2);
  border-radius: 20px;
  position: relative;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-4px);
    border-color: ${props => props.theme.palette.primary.main};
    box-shadow: 0 8px 30px rgba(139, 92, 246, 0.2);
  }
`;

const StepNumber = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(45deg, ${props => props.theme.palette.primary.main}, ${props => props.theme.palette.primary.light});
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.2rem;
  position: absolute;
  top: -20px;
  left: 32px;
  box-shadow: 0 4px 20px rgba(139, 92, 246, 0.3);
`;

const StepIcon = styled(Box)`
  width: 64px;
  height: 64px;
  border-radius: 16px;
  background: linear-gradient(135deg, rgba(139, 92, 246, 0.2) 0%, rgba(139, 92, 246, 0.1) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  
  svg {
    font-size: 32px;
    color: ${props => props.theme.palette.primary.main};
  }
`;

const ConnectorLine = styled(Box)`
  position: absolute;
  top: 50%;
  right: -30px;
  width: 60px;
  height: 2px;
  background: linear-gradient(90deg, ${props => props.theme.palette.primary.main}, ${props => props.theme.palette.primary.light});
  z-index: 0;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 8px solid ${props => props.theme.palette.primary.light};
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

const VerticalConnector = styled(Box)`
  position: absolute;
  left: 50%;
  bottom: -30px;
  width: 2px;
  height: 60px;
  background: linear-gradient(180deg, ${props => props.theme.palette.primary.main}, ${props => props.theme.palette.primary.light});
  z-index: 0;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-top: 8px solid ${props => props.theme.palette.primary.light};
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }

  @media (min-width: 901px) {
    display: none;
  }
`;

const steps = [
  {
    number: 1,
    icon: <RocketLaunchIcon />,
    title: 'Escolha seu Serviço',
    description: 'Selecione entre Elo Boost, Duo Boost, MD10 ou Coaching. Cada serviço é personalizado para suas necessidades.',
  },
  {
    number: 2,
    icon: <WhatsAppIcon />,
    title: 'Entre em Contato',
    description: 'Fale diretamente com nossa equipe via WhatsApp. Atendimento rápido e personalizado 24/7.',
  },
  {
    number: 3,
    icon: <PaymentsIcon />,
    title: 'Pagamento Seguro',
    description: 'Realize o pagamento de forma segura através dos métodos disponíveis. Aceitamos PIX, cartão e mais.',
  },
  {
    number: 4,
    icon: <SecurityIcon />,
    title: 'Segurança Garantida',
    description: 'Seus dados são protegidos com as melhores práticas de segurança. Utilizamos VPN e outros recursos.',
  },
  {
    number: 5,
    icon: <SupportAgentIcon />,
    title: 'Acompanhamento',
    description: 'Acompanhe o progresso em tempo real. Nossa equipe mantém você informado durante todo o processo.',
  },
  {
    number: 6,
    icon: <EmojiEventsIcon />,
    title: 'Objetivo Alcançado',
    description: 'Seu elo desejado é alcançado com qualidade e segurança. Satisfação 100% garantida.',
  },
];

const HowItWorks = () => {
  return (
    <PageWrapper>
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          align="center"
          sx={{
            fontSize: { xs: '2.5rem', md: '3.5rem' },
            fontWeight: 700,
            mb: 2,
            background: 'linear-gradient(45deg, #fff 30%, #E5E7EB 90%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Como Funciona
        </Typography>

        <Typography
          variant="h2"
          align="center"
          sx={{
            fontSize: { xs: '1.25rem', md: '1.5rem' },
            fontWeight: 400,
            color: 'text.secondary',
            mb: 8,
            maxWidth: 700,
            mx: 'auto',
          }}
        >
          Processo simples e transparente para você alcançar seu elo desejado com segurança e qualidade
        </Typography>

        <Grid container spacing={4}>
          {steps.map((step, index) => (
            <Grid item xs={12} md={4} key={step.number} sx={{ position: 'relative' }}>
              <StepCard>
                <StepNumber>{step.number}</StepNumber>
                <StepIcon>{step.icon}</StepIcon>
                <Typography variant="h6" gutterBottom>
                  {step.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {step.description}
                </Typography>
                {index < steps.length - 1 && index % 3 !== 2 && <ConnectorLine />}
                {index < steps.length - 1 && <VerticalConnector />}
              </StepCard>
            </Grid>
          ))}
        </Grid>

        <Box
          sx={{
            mt: 8,
            p: 4,
            borderRadius: 4,
            background: 'linear-gradient(135deg, rgba(139, 92, 246, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%)',
            border: '1px solid rgba(139, 92, 246, 0.2)',
            textAlign: 'center',
          }}
        >
          <Typography variant="h5" gutterBottom>
            Satisfação Garantida
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Se você não estiver satisfeito com nosso serviço, devolvemos seu dinheiro. 
            Nossa prioridade é sua satisfação e confiança.
          </Typography>
        </Box>
      </Container>
    </PageWrapper>
  );
};

export default HowItWorks; 