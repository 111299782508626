import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  Container,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useScrollTrigger,
  Slide,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import GroupsIcon from '@mui/icons-material/Groups';
import StarIcon from '@mui/icons-material/Star';
import SchoolIcon from '@mui/icons-material/School';
import LoginModal from '../LoginModal';
import ServiceRequestModal from '../ServiceRequestModal';

const StyledAppBar = styled(AppBar)`
  background: ${props => props['data-elevated'] 
    ? 'linear-gradient(135deg, rgba(10, 10, 10, 0.95) 0%, rgba(20, 20, 20, 0.95) 100%)'
    : 'transparent'};
  backdrop-filter: ${props => props['data-elevated'] ? 'blur(10px)' : 'none'};
  box-shadow: none;
  transition: all 0.3s ease;
`;

const LogoText = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin-right: 24px;
  text-transform: uppercase;
`;

const NavLink = styled(Button)`
  color: ${props => props.theme.palette.text.primary};
  font-weight: 500;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 2px;
    background: ${props => props.theme.palette.primary.main};
    transition: width 0.3s ease;
  }
  
  &:hover::after {
    width: 100%;
  }
`;

const MobileDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    background: linear-gradient(135deg, #0A0A0A 0%, #1A1A1A 100%);
    width: 100%;
    padding: 16px;
  }
`;

const StyledButton = styled(Button)`
  padding: 12px 32px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 12px;
  text-transform: none;
  transition: all 0.3s ease;

  &.MuiButton-contained {
    background: linear-gradient(45deg, ${props => props.theme.palette.primary.main}, ${props => props.theme.palette.primary.light});
    box-shadow: 0 4px 15px rgba(139, 92, 246, 0.3);

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 8px 25px rgba(139, 92, 246, 0.4);
    }
  }

  &.MuiButton-outlined {
    border: 2px solid ${props => props.theme.palette.primary.main};
    background: rgba(139, 92, 246, 0.1);

    &:hover {
      background: rgba(139, 92, 246, 0.2);
      border-color: ${props => props.theme.palette.primary.light};
      transform: translateY(-2px);
    }
  }
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background: linear-gradient(135deg, #18181B 0%, #27272A 100%);
    border-radius: 20px;
    border: 1px solid rgba(139, 92, 246, 0.2);
    max-width: 600px;
  }
`;

const StyledTextField = styled(TextField)`
  margin: 12px 0;
  
  .MuiOutlinedInput-root {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.primary.main};
    }
  }
`;

const StyledSelect = styled(Select)`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.palette.primary.main};
  }
`;

const ServiceOption = styled(Box)`
  padding: 16px;
  border-radius: 12px;
  border: 1px solid rgba(139, 92, 246, 0.2);
  background: rgba(139, 92, 246, 0.05);
  cursor: pointer;
  transition: all 0.2s ease;

  ${props => props.selected && `
    border-color: ${props.theme.palette.primary.main};
    background: rgba(139, 92, 246, 0.1);
  `}

  &:hover {
    border-color: ${props => props.theme.palette.primary.main};
    transform: translateY(-2px);
  }
`;

const Header = ({ onLoginClick, onRegisterClick }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [selectedService, setSelectedService] = useState('eloboost');
  const [serviceForm, setServiceForm] = useState({
    name: '',
    email: '',
    phone: '',
    currentElo: '',
    desiredElo: '',
    queue: 'solo',
    priority: false,
  });
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const phoneNumber = "+5515991909782";

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const navItems = [
    { label: 'Início', path: '/' },
    { label: 'Serviços', path: '/services' },
    { label: 'Preços', path: '/pricing' },
    { label: 'Como Funciona', path: '/how-it-works' },
  ];

  const eloOptions = [
    'Ferro IV', 'Ferro III', 'Ferro II', 'Ferro I',
    'Bronze IV', 'Bronze III', 'Bronze II', 'Bronze I',
    'Prata IV', 'Prata III', 'Prata II', 'Prata I',
    'Ouro IV', 'Ouro III', 'Ouro II', 'Ouro I',
    'Platina IV', 'Platina III', 'Platina II', 'Platina I',
    'Diamante IV', 'Diamante III', 'Diamante II', 'Diamante I',
  ];

  const services = [
    {
      id: 'eloboost',
      title: 'Elo Boost',
      description: 'Nós jogamos em sua conta até o elo desejado',
      icon: <RocketLaunchIcon />,
    },
    {
      id: 'duoboost',
      title: 'Duo Boost',
      description: 'Jogue junto com nossos boosters profissionais',
      icon: <GroupsIcon />,
    },
    {
      id: 'md10',
      title: 'MD5',
      description: 'Maximizamos seu elo inicial nas MD5',
      icon: <StarIcon />,
    },
    {
      id: 'coaching',
      title: 'Coaching',
      description: 'Aprenda com os melhores jogadores',
      icon: <SchoolIcon />,
    },
  ];

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const handleFormChange = (event) => {
    const { name, value, checked } = event.target;
    setServiceForm(prev => ({
      ...prev,
      [name]: name === 'priority' ? checked : value
    }));
  };

  const handleSubmit = () => {
    const message = `*Novo Pedido de ${services.find(s => s.id === selectedService).title}*
Nome: ${serviceForm.name}
Email: ${serviceForm.email}
Telefone: ${serviceForm.phone}
Elo Atual: ${serviceForm.currentElo}
Elo Desejado: ${serviceForm.desiredElo}
Fila: ${serviceForm.queue === 'solo' ? 'Solo/Duo' : 'Flex'}
Prioridade: ${serviceForm.priority ? 'Sim' : 'Não'}`;

    window.open(`https://wa.me/5515991909782?text=${encodeURIComponent(message)}`, '_blank');
    setOpenServiceModal(false);
    setServiceForm({
      name: '',
      email: '',
      phone: '',
      currentElo: '',
      desiredElo: '',
      queue: 'solo',
      priority: false,
    });
  };

  const handleNavigation = (path) => {
    navigate(path);
    setMobileOpen(false);
  };

  const drawer = (
    <Box sx={{ p: 2 }}>
      <List>
        {navItems.map((item) => (
          <ListItem
            key={item.path}
            button
            onClick={() => handleNavigation(item.path)}
            sx={{
              borderRadius: 2,
              mb: 1,
              '&:hover': {
                background: 'rgba(255, 87, 51, 0.08)',
              },
            }}
          >
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
        <ListItem button onClick={() => handleNavigation('/pricing')}>
          <ListItemText primary="Preços" />
        </ListItem>
        <ListItem button onClick={() => handleNavigation('/faq')}>
          <ListItemText primary="FAQ" />
        </ListItem>
        <ListItem button onClick={() => setLoginModalOpen(true)}>
          <ListItemText primary="Login" />
        </ListItem>
        <ListItem button component="a" href={`https://wa.me/${phoneNumber}`} target="_blank">
          <ListItemText 
            primary="Contato" 
            secondary={phoneNumber}
            secondaryTypographyProps={{ sx: { color: 'primary.main' } }}
          />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <Slide appear={false} direction="down" in={!trigger}>
        <StyledAppBar data-elevated={trigger}>
          <Container maxWidth="lg">
            <Toolbar disableGutters>
              <Box onClick={() => handleNavigation('/')} style={{ display: 'flex', alignItems: 'center' }}>
                <LogoText>ELOKING</LogoText>
              </Box>

              {!isMobile && (
                <>
                  {navItems.map((item) => (
                    <NavLink
                      key={item.path}
                      onClick={() => handleNavigation(item.path)}
                    >
                      {item.label}
                    </NavLink>
                  ))}
                </>
              )}

              <Box sx={{ flexGrow: 1 }} />

              <Stack
                direction="row"
                spacing={2}
                sx={{ display: { xs: 'none', md: 'flex' } }}
              >
                <StyledButton
                  variant="outlined"
                  onClick={() => setLoginModalOpen(true)}
                >
                  Login
                </StyledButton>
                <StyledButton
                  variant="contained"
                  onClick={() => setOpenServiceModal(true)}
                >
                  Começar
                </StyledButton>
              </Stack>

              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ ml: 2, display: { md: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </Container>
        </StyledAppBar>
      </Slide>

      {/* Modal de Serviço */}
      <StyledDialog 
        open={openServiceModal} 
        onClose={() => setOpenServiceModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h5">Começar Agora</Typography>
            <IconButton onClick={() => setOpenServiceModal(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 3 }}>
            Escolha o serviço que melhor atende suas necessidades
          </Typography>

          <Grid container spacing={2} sx={{ mb: 4 }}>
            {services.map((service) => (
              <Grid item xs={12} sm={6} key={service.id}>
                <ServiceOption
                  selected={selectedService === service.id}
                  onClick={() => setSelectedService(service.id)}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Box sx={{ 
                      p: 1.5,
                      borderRadius: '12px',
                      background: 'rgba(139, 92, 246, 0.1)',
                      color: 'primary.main'
                    }}>
                      {service.icon}
                    </Box>
                    <Box>
                      <Typography variant="h6">{service.title}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {service.description}
                      </Typography>
                    </Box>
                  </Stack>
                </ServiceOption>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ mt: 2 }}>
            <StyledTextField
              fullWidth
              label="Nome completo"
              name="name"
              value={serviceForm.name}
              onChange={handleFormChange}
              margin="normal"
            />
            <StyledTextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={serviceForm.email}
              onChange={handleFormChange}
              margin="normal"
            />
            <StyledTextField
              fullWidth
              label="Telefone/WhatsApp"
              name="phone"
              value={serviceForm.phone}
              onChange={handleFormChange}
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Elo Atual</InputLabel>
              <StyledSelect
                name="currentElo"
                value={serviceForm.currentElo}
                onChange={handleFormChange}
                label="Elo Atual"
              >
                {eloOptions.map(elo => (
                  <MenuItem key={elo} value={elo}>{elo}</MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Elo Desejado</InputLabel>
              <StyledSelect
                name="desiredElo"
                value={serviceForm.desiredElo}
                onChange={handleFormChange}
                label="Elo Desejado"
              >
                {eloOptions.map(elo => (
                  <MenuItem key={elo} value={elo}>{elo}</MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Tipo de Fila</InputLabel>
              <StyledSelect
                name="queue"
                value={serviceForm.queue}
                onChange={handleFormChange}
                label="Tipo de Fila"
              >
                <MenuItem value="solo">Solo/Duo</MenuItem>
                <MenuItem value="flex">Flex</MenuItem>
              </StyledSelect>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  name="priority"
                  checked={serviceForm.priority}
                  onChange={handleFormChange}
                  color="primary"
                />
              }
              label="Serviço Prioritário (+20%)"
              sx={{ mt: 2 }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={() => setOpenServiceModal(false)} color="inherit">
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            startIcon={<WhatsAppIcon />}
          >
            Solicitar via WhatsApp
          </Button>
        </DialogActions>
      </StyledDialog>

      <MobileDrawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box sx={{ textAlign: 'right', mb: 2 }}>
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </Box>
        {drawer}
      </MobileDrawer>

      <LoginModal
        open={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
        onRegisterClick={() => {
          setLoginModalOpen(false);
          setOpenServiceModal(true);
        }}
      />

      <ServiceRequestModal
        open={openServiceModal}
        onClose={() => setOpenServiceModal(false)}
      />
    </>
  );
};

export default Header; 