import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @keyframes twinkling {
    0% { opacity: 0.3; }
    50% { opacity: 0.6; }
    100% { opacity: 0.3; }
  }

  @keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-20px); }
    100% { transform: translateY(0px); }
  }

  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }

  @keyframes gradientBG {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    padding: 0;
    background: ${props => props.theme.palette.background.default};
    color: ${props => props.theme.palette.text.primary};
    font-family: ${props => props.theme.typography.fontFamily};
    overflow-x: hidden;
  }

  ::-webkit-scrollbar {
    width: 10px;
    background: ${props => props.theme.palette.background.default};
  }

  ::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom, ${props => props.theme.palette.primary.main}, ${props => props.theme.palette.primary.light});
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.palette.background.paper};
  }

  ::selection {
    background: ${props => props.theme.palette.primary.main};
    color: white;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    cursor: pointer;
    border: 0;
    outline: 0;
  }

  img {
    max-width: 100%;
    display: block;
  }

  .gradient-text {
    background: linear-gradient(45deg, ${props => props.theme.palette.primary.main}, ${props => props.theme.palette.primary.light});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .floating {
    animation: float 6s ease-in-out infinite;
  }

  .pulsing {
    animation: pulse 2s ease-in-out infinite;
  }

  .gradient-bg {
    background: linear-gradient(-45deg, 
      ${props => props.theme.palette.background.default},
      ${props => props.theme.palette.primary.dark},
      ${props => props.theme.palette.primary.main},
      ${props => props.theme.palette.background.paper}
    );
    background-size: 400% 400%;
    animation: gradientBG 15s ease infinite;
  }

  .blur-bg {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }

  .glass {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

export default GlobalStyles; 