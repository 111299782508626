import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Stack,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import styled from 'styled-components';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import DiamondIcon from '@mui/icons-material/Diamond';
import StarIcon from '@mui/icons-material/Star';
import SecurityIcon from '@mui/icons-material/Security';
import SpeedIcon from '@mui/icons-material/Speed';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const PageWrapper = styled(Box)`
  background: linear-gradient(135deg, #09090B 0%, #1A1A1A 100%);
  min-height: 100vh;
  padding: 120px 0 80px;
`;

const ServiceCard = styled(Card)`
  height: 100%;
  background: linear-gradient(135deg, rgba(139, 92, 246, 0.1) 0%, rgba(139, 92, 246, 0.05) 100%);
  border: 1px solid rgba(139, 92, 246, 0.2);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, ${props => props.theme.palette.primary.main}, ${props => props.theme.palette.primary.light});
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    transform: translateY(-8px);
    border-color: ${props => props.theme.palette.primary.main};
    box-shadow: 0 12px 24px rgba(139, 92, 246, 0.2);

    &::before {
      opacity: 1;
    }
  }
`;

const ServiceIcon = styled(Box)`
  width: 64px;
  height: 64px;
  border-radius: 16px;
  background: linear-gradient(135deg, rgba(139, 92, 246, 0.2) 0%, rgba(139, 92, 246, 0.1) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  svg {
    font-size: 32px;
    color: ${props => props.theme.palette.primary.main};
  }
`;

const PriceTag = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(45deg, #fff, ${props => props.theme.palette.primary.light});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 16px 0;
`;

const Feature = styled(ListItem)`
  padding: 8px 0;
  
  .MuiListItemIcon-root {
    min-width: 32px;
    color: ${props => props.theme.palette.primary.main};
  }
`;

const Services = () => {
  const services = [
    {
      icon: <RocketLaunchIcon />,
      title: 'Solo/Duo Boost',
      description: 'Alcance seu elo desejado com nossos boosters profissionais jogando em sua conta.',
      features: [
        'Boosters Challenger/GM',
        'Prazo médio de 5-7 dias',
        'Escolha de campeões',
        'Streams privadas',
      ],
    },
    {
      icon: <DiamondIcon />,
      title: 'Duo Boost',
      description: 'Jogue junto com nossos boosters e aprenda enquanto sobe de elo.',
      features: [
        'Jogue com boosters profissionais',
        'Aprenda enquanto sobe',
        'Coaching incluso',
        'Horários flexíveis',
      ],
    },
    {
      icon: <StarIcon />,
      title: 'MD5',
      description: 'Maximize seu elo inicial com nossas MD5.',
      features: [
        'Até 5 partidas',
        'Elo garantido',
        'Prioridade na fila',
        'Bônus de vitórias',
      ],
    },
  ];

  const handleWhatsApp = (service) => {
    const message = `Olá! Gostaria de saber mais sobre o serviço de ${service}`;
    window.open(`https://wa.me/5515991909782?text=${encodeURIComponent(message)}`, '_blank');
  };

  return (
    <PageWrapper>
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          sx={{ mb: 2 }}
        >
          Nossos Serviços
        </Typography>
        
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          sx={{ mb: 6, maxWidth: 600, mx: 'auto' }}
        >
          Escolha o serviço que melhor atende suas necessidades e alcance o elo que você merece
        </Typography>

        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} md={4} key={index}>
              <ServiceCard>
                <CardContent sx={{ p: 4 }}>
                  <ServiceIcon>
                    {service.icon}
                  </ServiceIcon>

                  <Stack direction="row" spacing={1} mb={2}>
                    <Typography variant="h5" gutterBottom>
                      {service.title}
                    </Typography>
                    <Chip
                      label="Popular"
                      color="primary"
                      size="small"
                      sx={{ display: index === 0 ? 'inline-flex' : 'none' }}
                    />
                  </Stack>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    paragraph
                  >
                    {service.description}
                  </Typography>

                  <List>
                    {service.features.map((feature, idx) => (
                      <Feature key={idx} disableGutters>
                        <ListItemIcon>
                          <CheckCircleIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={feature}
                          primaryTypographyProps={{
                            variant: 'body2',
                            color: 'text.secondary',
                          }}
                        />
                      </Feature>
                    ))}
                  </List>

                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    startIcon={<WhatsAppIcon />}
                    onClick={() => handleWhatsApp(service.title)}
                    sx={{ mt: 3 }}
                  >
                    Consultar Preço
                  </Button>
                </CardContent>
              </ServiceCard>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 8, textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            Por que escolher nossos serviços?
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            <Grid item xs={12} sm={6} md={3}>
              <SecurityIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
              <Typography variant="h6" gutterBottom>
                Segurança Garantida
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Sua conta está protegida com as melhores práticas de segurança
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SpeedIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
              <Typography variant="h6" gutterBottom>
                Entrega Rápida
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Boosters online 24/7 para agilizar seu serviço
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SupportAgentIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
              <Typography variant="h6" gutterBottom>
                Suporte Premium
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Atendimento personalizado via WhatsApp
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StarIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
              <Typography variant="h6" gutterBottom>
                Satisfação 100%
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Garantimos sua satisfação ou seu dinheiro de volta
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </PageWrapper>
  );
};

export default Services; 