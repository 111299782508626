import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Avatar,
} from '@mui/material';
import styled from 'styled-components';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import { auth } from '../../firebase';

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 280px;
    background: linear-gradient(180deg, #1a1a1a 0%, #2d2d2d 100%);
    border-right: 1px solid rgba(139, 92, 246, 0.2);
    padding: 24px 16px;
  }
`;

const ProfileSection = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  margin-bottom: 24px;
  border-radius: 12px;
  background: rgba(139, 92, 246, 0.1);
  border: 1px solid rgba(139, 92, 246, 0.2);
`;

const StyledAvatar = styled(Avatar)`
  width: 48px;
  height: 48px;
  background-color: #8B5CF6;
  border: 2px solid rgba(139, 92, 246, 0.3);
`;

const MenuItem = styled(ListItem)`
  border-radius: 8px;
  margin-bottom: 8px;
  
  &:hover {
    background: rgba(139, 92, 246, 0.1);
  }
  
  &.active {
    background: rgba(139, 92, 246, 0.2);
  }
`;

const Sidebar = () => {
  const navigate = useNavigate();
  const currentUser = auth.currentUser;

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  };

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
    { text: 'Avisos', icon: <NotificationsIcon />, path: '/dashboard/notifications' },
  ];

  return (
    <StyledDrawer variant="permanent">
      <ProfileSection>
        <StyledAvatar>
          {currentUser?.email?.charAt(0).toUpperCase()}
        </StyledAvatar>
        <Box>
          <Typography variant="subtitle1" sx={{ color: 'white' }}>
            {currentUser?.email?.split('@')[0]}
          </Typography>
          <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            Cliente
          </Typography>
        </Box>
      </ProfileSection>

      <Divider sx={{ borderColor: 'rgba(139, 92, 246, 0.2)', mb: 2 }} />

      <List>
        {menuItems.map((item) => (
          <MenuItem
            button
            key={item.text}
            onClick={() => navigate(item.path)}
          >
            <ListItemIcon sx={{ color: 'primary.main' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </MenuItem>
        ))}
      </List>

      <Box sx={{ flexGrow: 1 }} />

      <MenuItem button onClick={handleLogout} sx={{ mt: 2 }}>
        <ListItemIcon sx={{ color: 'error.main' }}>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Sair" />
      </MenuItem>
    </StyledDrawer>
  );
};

export default Sidebar; 