import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Chip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  TextField,
  Snackbar,
  Alert,
  Slider,
} from '@mui/material';
import styled from 'styled-components';
import AdminSidebar from '../../components/AdminSidebar';
import { collection, getDocs, deleteDoc, doc, updateDoc, addDoc, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CreateServiceModal from '../../components/CreateServiceModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { auth } from '../../firebase';

const DashboardRoot = styled(Box)`
  display: flex;
  min-height: 100vh;
  background: ${props => props.theme.palette.background.default};
`;

const DashboardContent = styled(Box)`
  flex-grow: 1;
  padding: 32px;
  margin-left: 280px;
  width: calc(100% - 280px);
  box-sizing: border-box;
`;

const GridContainer = styled(Grid)`
  margin: 0 !important;
  width: 100% !important;
  
  .MuiGrid-item {
    padding: 16px;
  }
`;

const ServiceCard = styled(Card)`
  background: #1a1625;
  border-radius: 16px;
  transition: all 0.3s ease;
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid rgba(139, 92, 246, 0.1);

  &:hover {
    border-color: rgba(139, 92, 246, 0.3);
  }
`;

const ServiceCardContent = styled(CardContent)`
  padding: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const ServiceInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  margin-right: 24px;
`;

const ServiceTitle = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  margin-bottom: 4px;
`;

const ServiceSubtitle = styled(Typography)`
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ServiceDetails = styled(Box)`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ServicePrice = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 500;
  color: #8B5CF6;
  white-space: nowrap;
`;

const ActionButtons = styled(Box)`
  display: flex;
  gap: 8px;
  opacity: 0;
  transition: opacity 0.2s ease;

  ${ServiceCard}:hover & {
    opacity: 1;
  }
`;

const PageHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  padding: 0 16px;
`;

const NotificationDialog = styled(Dialog)`
  .MuiDialog-paper {
    background: #1a1625;
    border: 1px solid rgba(139, 92, 246, 0.2);
  }
`;

const ServiceStatus = styled.div`
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: 500;
  background: ${props => props.status === 'completed' ? 'rgba(34, 197, 94, 0.1)' : 'rgba(139, 92, 246, 0.1)'};
  color: ${props => props.status === 'completed' ? '#22c55e' : '#8B5CF6'};
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;

const ProgressDialog = styled(Dialog)`
  .MuiDialog-paper {
    background: #1a1625;
    border: 1px solid rgba(139, 92, 246, 0.2);
  }
`;

const StyledSlider = styled(Slider)`
  color: #8B5CF6;
  height: 8px;
  
  .MuiSlider-thumb {
    width: 24px;
    height: 24px;
    background: #8B5CF6;
    border: 2px solid #fff;
    box-shadow: 0 0 10px rgba(139, 92, 246, 0.3);
    
    &:hover, &.Mui-focusVisible {
      box-shadow: 0 0 0 8px rgba(139, 92, 246, 0.16);
    }
  }

  .MuiSlider-rail {
    background: rgba(139, 92, 246, 0.1);
  }
`;

const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [progressModalOpen, setProgressModalOpen] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);

  const fetchServices = async () => {
    try {
      const servicesSnapshot = await getDocs(collection(db, 'services'));
      const servicesData = servicesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setServices(servicesData);
    } catch (error) {
      console.error('Erro ao buscar serviços:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  const handleCreateSuccess = () => {
    fetchServices();
  };

  const handleDeleteClick = (service) => {
    setSelectedService(service);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteDoc(doc(db, 'services', selectedService.id));
      await fetchServices();
      setDeleteConfirmOpen(false);
      setSelectedService(null);
    } catch (error) {
      console.error('Erro ao deletar serviço:', error);
    }
  };

  const handleUpdateProgress = (service) => {
    setSelectedService(service);
    setCurrentProgress(service.progress || 0);
    setProgressModalOpen(true);
  };

  const handleProgressChange = (event, newValue) => {
    setCurrentProgress(newValue);
  };

  const handleProgressSubmit = async () => {
    try {
      await updateDoc(doc(db, 'services', selectedService.id), {
        progress: currentProgress,
        updatedAt: new Date()
      });
      
      setSnackbarMessage('Progresso atualizado com sucesso!');
      setSnackbarOpen(true);
      setProgressModalOpen(false);
      await fetchServices();
    } catch (error) {
      console.error('Erro ao atualizar progresso:', error);
      setSnackbarMessage('Erro ao atualizar progresso. Tente novamente.');
      setSnackbarOpen(true);
    }
  };

  const handleCompleteService = async (service) => {
    try {
      await updateDoc(doc(db, 'services', service.id), {
        status: 'completed',
        completedAt: new Date()
      });
      await fetchServices();
    } catch (error) {
      console.error('Erro ao concluir serviço:', error);
    }
  };

  const handleSendNotification = async () => {
    try {
      if (!selectedService || !notificationMessage) return;

      const currentUser = auth.currentUser;
      if (!currentUser) {
        setSnackbarMessage('Usuário não autenticado');
        setSnackbarOpen(true);
        return;
      }

      // Primeiro, verificar se o usuário é admin
      const userDoc = await getDocs(query(
        collection(db, 'users'),
        where('email', '==', currentUser.email)
      ));

      if (userDoc.empty || userDoc.docs[0].data().role !== 'admin') {
        setSnackbarMessage('Permissão negada: apenas administradores podem enviar avisos');
        setSnackbarOpen(true);
        return;
      }

      // Se chegou aqui, é admin e pode enviar a notificação
      await addDoc(collection(db, 'notifications'), {
        userId: selectedService.assignedTo.id,
        message: notificationMessage,
        serviceId: selectedService.id,
        serviceName: selectedService.name,
        createdAt: new Date(),
        read: false,
        createdBy: currentUser.uid, // Adicionar quem criou a notificação
        type: 'service_notification' // Adicionar tipo de notificação
      });

      setSnackbarMessage('Aviso enviado com sucesso!');
      setSnackbarOpen(true);
      setNotificationOpen(false);
      setNotificationMessage('');
    } catch (error) {
      console.error('Erro ao enviar aviso:', error);
      setSnackbarMessage('Erro ao enviar aviso. Tente novamente.');
      setSnackbarOpen(true);
    }
  };

  const handleOpenNotification = (service) => {
    setSelectedService(service);
    setNotificationOpen(true);
  };

  return (
    <DashboardRoot>
      <AdminSidebar />
      <DashboardContent>
        <PageHeader>
          <Typography variant="h4" fontWeight="bold">
            Serviços Disponíveis
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setOpenCreateModal(true)}
          >
            Criar Novo Serviço
          </Button>
        </PageHeader>

        <Box>
          {services.map((service) => (
            <ServiceCard key={service.id}>
              <ServiceCardContent>
                <ServiceInfo>
                  <Box>
                    <ServiceTitle>
                      {service.name}
                    </ServiceTitle>
                    <ServiceSubtitle>
                      {service.category} • {service.duration} dias
                    </ServiceSubtitle>
                  </Box>

                  <ServiceDetails>
                    {service.assignedTo && (
                      <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
                        <strong>Cliente:</strong> {service.assignedTo.email}
                      </Typography>
                    )}
                    <Box mt={1}>
                      <ServiceStatus status={service.status}>
                        {service.status === 'completed' ? (
                          <>
                            <CheckCircleIcon fontSize="small" />
                            Concluído
                          </>
                        ) : (
                          'Em andamento'
                        )}
                      </ServiceStatus>
                    </Box>
                    {service.status === 'completed' && service.completedAt && (
                      <Typography variant="body2" color="rgba(255, 255, 255, 0.6)" mt={1}>
                        Concluído em {new Date(service.completedAt.toDate()).toLocaleDateString('pt-BR')}
                      </Typography>
                    )}
                  </ServiceDetails>
                </ServiceInfo>

                <Box display="flex" alignItems="center" gap={3}>
                  <ServicePrice>
                    R$ {service.price}
                  </ServicePrice>
                  
                  <ActionButtons>
                    {service.assignedTo && (
                      <Tooltip title="Enviar Aviso">
                        <IconButton
                          size="small"
                          sx={{ color: '#8B5CF6' }}
                          onClick={() => handleOpenNotification(service)}
                        >
                          <NotificationsIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Atualizar Progresso">
                      <IconButton
                        size="small"
                        sx={{ color: '#8B5CF6' }}
                        onClick={() => handleUpdateProgress(service)}
                      >
                        <UpdateIcon />
                      </IconButton>
                    </Tooltip>
                    {service.status !== 'completed' && (
                      <Tooltip title="Concluir Serviço">
                        <IconButton
                          size="small"
                          sx={{ color: '#22c55e' }}
                          onClick={() => handleCompleteService(service)}
                        >
                          <CheckCircleIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Deletar Serviço">
                      <IconButton
                        size="small"
                        sx={{ color: '#ef4444' }}
                        onClick={() => handleDeleteClick(service)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </ActionButtons>
                </Box>
              </ServiceCardContent>
            </ServiceCard>
          ))}

          {services.length === 0 && !loading && (
            <Box textAlign="center" py={4}>
              <Typography variant="body1" color="textSecondary">
                Nenhum serviço cadastrado
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setOpenCreateModal(true)}
                sx={{ mt: 2 }}
              >
                Criar Primeiro Serviço
              </Button>
            </Box>
          )}
        </Box>

        <CreateServiceModal
          open={openCreateModal}
          onClose={() => setOpenCreateModal(false)}
          onSuccess={handleCreateSuccess}
        />

        <Dialog
          open={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
        >
          <DialogTitle>Confirmar Exclusão</DialogTitle>
          <DialogContent>
            <Typography>
              Tem certeza que deseja excluir o serviço "{selectedService?.name}"?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteConfirmOpen(false)}>
              Cancelar
            </Button>
            <Button onClick={handleDeleteConfirm} color="error" variant="contained">
              Excluir
            </Button>
          </DialogActions>
        </Dialog>

        <NotificationDialog
          open={notificationOpen}
          onClose={() => setNotificationOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            Enviar Aviso
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
              Enviando aviso para: {selectedService?.assignedTo?.email}
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={notificationMessage}
              onChange={(e) => setNotificationMessage(e.target.value)}
              placeholder="Digite sua mensagem..."
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  background: 'rgba(255, 255, 255, 0.05)'
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setNotificationOpen(false)}>
              Cancelar
            </Button>
            <Button
              onClick={handleSendNotification}
              variant="contained"
              color="primary"
              disabled={!notificationMessage}
            >
              Enviar
            </Button>
          </DialogActions>
        </NotificationDialog>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarMessage.includes('Erro') ? 'error' : 'success'}
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <ProgressDialog
          open={progressModalOpen}
          onClose={() => setProgressModalOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            Atualizar Progresso
          </DialogTitle>
          <DialogContent>
            <Box sx={{ mb: 3 }}>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Serviço: {selectedService?.name}
              </Typography>
              {selectedService?.assignedTo && (
                <Typography variant="body2" color="textSecondary">
                  Cliente: {selectedService.assignedTo.email}
                </Typography>
              )}
            </Box>

            <Box sx={{ px: 3 }}>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Progresso Atual: {currentProgress}%
              </Typography>
              <StyledSlider
                value={currentProgress}
                onChange={handleProgressChange}
                aria-label="Progresso"
                valueLabelDisplay="auto"
                step={5}
                marks
                min={0}
                max={100}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setProgressModalOpen(false)}>
              Cancelar
            </Button>
            <Button
              onClick={handleProgressSubmit}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </ProgressDialog>
      </DashboardContent>
    </DashboardRoot>
  );
};

export default Services; 