import React from 'react';
import {
  Box,
  Container,
  Typography,
  Stack,
} from '@mui/material';
import styled from 'styled-components';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';

const FooterWrapper = styled.footer`
  background: linear-gradient(135deg, rgba(10, 10, 10, 0.95) 0%, rgba(20, 20, 20, 0.95) 100%);
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const LogoText = styled(Typography)`
  font-size: 28px;
  font-weight: 800;
  letter-spacing: 1px;
  background: linear-gradient(45deg, #8B5CF6, #6D28D9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: 'Inter', sans-serif;
`;

const SocialButton = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  transition: all 0.3s ease;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: #8B5CF6;
    transform: translateY(-2px);
  }
`;

const Footer = () => {
  const phoneNumber = "+5515991909782";

  return (
    <FooterWrapper>
      <Container maxWidth="lg">
        <Box py={6} display="flex" flexDirection="column" alignItems="center">
          <Box mb={4}>
            <LogoText variant="h1">
              ELOKING
            </LogoText>
          </Box>
          
          <Stack direction="row" spacing={2} mb={4}>
            <SocialButton 
              href={`https://wa.me/${phoneNumber}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsAppIcon />
            </SocialButton>
            <SocialButton 
              href="https://www.instagram.com/elojob.king"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </SocialButton>
          </Stack>

          <Typography variant="body2" color="text.secondary" align="center">
            © {new Date().getFullYear()} EloKing. Todos os direitos reservados.
          </Typography>
        </Box>
      </Container>
    </FooterWrapper>
  );
};

export default Footer; 