import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  Avatar,
  Rating,
  Chip,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Divider,
  Paper,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import SecurityIcon from '@mui/icons-material/Security';
import SpeedIcon from '@mui/icons-material/Speed';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import StarIcon from '@mui/icons-material/Star';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PeopleIcon from '@mui/icons-material/People';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DiamondIcon from '@mui/icons-material/Diamond';
import TimelineIcon from '@mui/icons-material/Timeline';
import GroupsIcon from '@mui/icons-material/Groups';
import ServiceRequestModal from '../../components/ServiceRequestModal';

const HeroSection = styled(Box)`
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 120px 0;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(9, 9, 11, 0.9) 0%,
      rgba(9, 9, 11, 0.7) 50%,
      rgba(9, 9, 11, 0.9) 100%
    );
    z-index: 1;
  }
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;

const HeroContent = styled(Box)`
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 800px;
`;

const HeroChip = styled(Chip)`
  margin-bottom: 32px;
  background: rgba(139, 92, 246, 0.2);
  border: 1px solid ${props => props.theme.palette.primary.main};
  backdrop-filter: blur(4px);
  padding: 4px 12px;
  height: 36px;
  
  .MuiChip-label {
    font-weight: 600;
    font-size: 0.9rem;
  }
`;

const HeroTitle = styled(Typography)`
  font-size: 5rem;
  font-weight: 800;
  background: linear-gradient(45deg, #fff, ${props => props.theme.palette.primary.light});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  margin-bottom: 32px;
  line-height: 1.1;
  letter-spacing: -1px;

  @media (max-width: 900px) {
    font-size: 4rem;
  }

  @media (max-width: 600px) {
    font-size: 3rem;
  }
`;

const HeroSubtitle = styled(Typography)`
  font-size: 1.25rem;
  color: ${props => props.theme.palette.text.secondary};
  margin-bottom: 48px;
  max-width: 600px;
  line-height: 1.6;
  opacity: 0.9;
`;

const HeroButtons = styled(Stack)`
  margin-top: 48px;
`;

const PrimaryButton = styled(Button)`
  padding: 12px 36px;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 12px;
  text-transform: none;
  background: ${props => props.theme.palette.primary.main};
  transition: all 0.3s ease;

  &:hover {
    background: ${props => props.theme.palette.primary.dark};
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(139, 92, 246, 0.3);
  }
`;

const SecondaryButton = styled(Button)`
  padding: 12px 36px;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 12px;
  text-transform: none;
  border: 2px solid ${props => props.theme.palette.primary.main};
  color: ${props => props.theme.palette.primary.main};
  background: rgba(139, 92, 246, 0.1);
  backdrop-filter: blur(4px);

  &:hover {
    background: rgba(139, 92, 246, 0.2);
    border-color: ${props => props.theme.palette.primary.light};
    transform: translateY(-2px);
  }
`;

const HeroImage = styled.img`
  position: absolute;
  right: -5%;
  bottom: 0;
  height: 90%;
  max-height: 800px;
  object-fit: contain;
  pointer-events: none;

  @media (max-width: 1200px) {
    opacity: 0.5;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const FeatureCard = styled(Card)`
  height: 100%;
  background: ${props => props.theme.palette.background.paper};
  transition: transform 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-8px);
  }
`;

const StyledIcon = styled(Box)`
  color: ${props => props.theme.palette.secondary.main};
  font-size: 48px;
  margin-bottom: 16px;
`;

const StatSection = styled(Box)`
  background: ${props => props.theme.palette.background.paper};
  padding: 80px 0;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, ${props => props.theme.palette.secondary.main}, ${props => props.theme.palette.secondary.light});
  }
`;

const StatCard = styled(Box)`
  text-align: center;
  padding: 24px;
`;

const StatValue = styled(Typography)`
  font-size: 3rem;
  font-weight: 700;
  color: ${props => props.theme.palette.secondary.main};
  margin-bottom: 8px;
`;

const TestimonialCard = styled(Card)`
  height: 100%;
  background: ${props => props.theme.palette.background.paper};
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-8px);
  }
`;

const TestimonialAvatar = styled(Avatar)`
  width: 64px;
  height: 64px;
  margin-bottom: 16px;
`;

const GradientButton = styled(Button)`
  background: linear-gradient(45deg, ${props => props.theme.palette.secondary.main}, ${props => props.theme.palette.secondary.light});
  color: white;
  padding: 12px 32px;
  font-size: 1.1rem;
  border-radius: 50px;
  text-transform: none;
  transition: all 0.3s ease;
  border: none;
  
  &:hover {
    background: linear-gradient(45deg, ${props => props.theme.palette.secondary.light}, ${props => props.theme.palette.secondary.main});
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(255, 87, 51, 0.3);
  }
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background: ${props => props.theme.palette.background.paper};
    border-radius: 20px;
    padding: 16px;
  }
`;

const StyledTextField = styled(TextField)`
  margin: 12px 0;
  
  .MuiOutlinedInput-root {
    border-radius: 12px;
    
    &:hover fieldset {
      border-color: ${props => props.theme.palette.secondary.main};
    }
  }
`;

const ProcessStep = styled(Paper)`
  padding: 24px;
  text-align: center;
  height: 100%;
  background: linear-gradient(135deg, rgba(107, 70, 193, 0.1) 0%, rgba(255, 87, 51, 0.1) 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-8px);
    border-color: ${props => props.theme.palette.secondary.main};
  }
`;

const Home = () => {
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [serviceModalOpen, setServiceModalOpen] = useState(false);
  
  const features = [
    {
      icon: <SecurityIcon sx={{ fontSize: 40 }} />,
      title: 'Segurança Garantida',
      description: 'Sua conta está segura conosco. Utilizamos as melhores práticas de segurança do mercado.',
    },
    {
      icon: <SpeedIcon sx={{ fontSize: 40 }} />,
      title: 'Serviço Rápido',
      description: 'Boosters profissionais prontos para elevar seu ranking o mais rápido possível.',
    },
    {
      icon: <SupportAgentIcon sx={{ fontSize: 40 }} />,
      title: 'Suporte 24/7',
      description: 'Nossa equipe está sempre disponível para ajudar você em qualquer situação.',
    },
    {
      icon: <StarIcon sx={{ fontSize: 40 }} />,
      title: 'Qualidade Premium',
      description: 'Serviço de alta qualidade com garantia de satisfação do cliente.',
    },
  ];

  const stats = [
    { value: '5000+', label: 'Clientes Satisfeitos', icon: <PeopleIcon /> },
    { value: '98%', label: 'Taxa de Sucesso', icon: <CheckCircleIcon /> },
    { value: '24/7', label: 'Suporte Disponível', icon: <SupportAgentIcon /> },
    { value: '50+', label: 'Boosters Profissionais', icon: <EmojiEventsIcon /> },
  ];

  const testimonials = [
    {
      name: 'João Silva',
      rank: 'Diamante II',
      avatar: '/images/avatar1.jpg',
      comment: 'Serviço incrível! Em apenas uma semana consegui subir do Ouro para o Platina.',
      rating: 5,
    },
    {
      name: 'Maria Santos',
      rank: 'Platina I',
      avatar: '/images/avatar2.jpg',
      comment: 'Os boosters são muito profissionais e o suporte é excelente. Recomendo!',
      rating: 5,
    },
    {
      name: 'Pedro Costa',
      rank: 'Diamante IV',
      avatar: '/images/avatar3.jpg',
      comment: 'Melhor serviço de boost que já usei. Rápido, seguro e eficiente.',
      rating: 5,
    },
  ];

  const process = [
    {
      icon: <PersonIcon sx={{ fontSize: 40, color: 'secondary.main' }} />,
      title: 'Crie sua conta',
      description: 'Registre-se gratuitamente em menos de 1 minuto',
    },
    {
      icon: <DiamondIcon sx={{ fontSize: 40, color: 'secondary.main' }} />,
      title: 'Escolha seu boost',
      description: 'Selecione o serviço que melhor atende suas necessidades',
    },
    {
      icon: <TimelineIcon sx={{ fontSize: 40, color: 'secondary.main' }} />,
      title: 'Acompanhe o progresso',
      description: 'Monitore em tempo real o avanço do seu boost',
    },
    {
      icon: <GroupsIcon sx={{ fontSize: 40, color: 'secondary.main' }} />,
      title: 'Alcance seus objetivos',
      description: 'Chegue ao elo que você sempre sonhou',
    },
  ];

  const handleLoginOpen = () => setOpenLogin(true);
  const handleLoginClose = () => setOpenLogin(false);
  const handleRegisterOpen = () => setOpenRegister(true);
  const handleRegisterClose = () => setOpenRegister(false);

  const handleWhatsAppClick = () => {
    const message = "Olá! Gostaria de saber mais sobre os serviços de boost.";
    const whatsappUrl = `https://wa.me/5515991909782?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <>
      <Box>
        <HeroSection>
          <VideoBackground autoPlay muted loop playsInline>
            <source src="/src/video.webm" type="video/webp" />
          </VideoBackground>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12} md={8}>
                <HeroContent>
                  <HeroChip
                    label="A Temporada 2025 Começou!"
                    color="primary"
                  />
                  <HeroTitle variant="h1">
                    CONQUISTE O
                    <br />
                    ELO QUE VOCÊ
                    <br />
                    MERECE
                  </HeroTitle>
                  <HeroSubtitle>
                    Alcance seus objetivos com nossa equipe de boosters profissionais.
                    Serviço rápido, seguro e com garantia de satisfação.
                  </HeroSubtitle>
                  <HeroButtons direction="row" spacing={3}>
                    <PrimaryButton
                      onClick={() => setServiceModalOpen(true)}
                      variant="contained"
                      endIcon={<ArrowForwardIcon />}
                    >
                      Comece agora
                    </PrimaryButton>
                    <SecondaryButton
                      startIcon={<WhatsAppIcon />}
                      onClick={handleWhatsAppClick}
                    >
                      Fale Conosco
                    </SecondaryButton>
                  </HeroButtons>
                </HeroContent>
              </Grid>
            </Grid>
          </Container>
        </HeroSection>

        <ServiceRequestModal
          open={serviceModalOpen}
          onClose={() => setServiceModalOpen(false)}
        />

        <StatSection>
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              {stats.map((stat, index) => (
                <Grid item xs={6} md={3} key={index}>
                  <StatCard>
                    <Box sx={{ color: 'secondary.main', mb: 2 }}>
                      {stat.icon}
                    </Box>
                    <StatValue variant="h3">
                      {stat.value}
                    </StatValue>
                    <Typography variant="subtitle1" color="textSecondary">
                      {stat.label}
                    </Typography>
                  </StatCard>
                </Grid>
              ))}
            </Grid>
          </Container>
        </StatSection>

        <Box sx={{ py: 8 }}>
          <Container maxWidth="lg">
            <Typography
              variant="h2"
              align="center"
              gutterBottom
              sx={{ mb: 6 }}
            >
              Por que escolher a ELOKING?
            </Typography>

            <Grid container spacing={4}>
              {features.map((feature, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <FeatureCard>
                    <CardContent sx={{ textAlign: 'center', p: 3 }}>
                      <StyledIcon>{feature.icon}</StyledIcon>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontWeight: 600 }}
                      >
                        {feature.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                      >
                        {feature.description}
                      </Typography>
                    </CardContent>
                  </FeatureCard>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        <Box sx={{ py: 8, background: 'background.paper' }}>
          <Container maxWidth="lg">
            <Typography
              variant="h2"
              align="center"
              gutterBottom
              sx={{ mb: 6 }}
            >
              O que nossos clientes dizem
            </Typography>

            <Grid container spacing={4}>
              {testimonials.map((testimonial, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <TestimonialCard>
                    <CardContent sx={{ textAlign: 'center', p: 4 }}>
                      <TestimonialAvatar
                        src={testimonial.avatar}
                        alt={testimonial.name}
                      />
                      <Typography variant="h6 gutterBottom">
                        {testimonial.name}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        gutterBottom
                      >
                        {testimonial.rank}
                      </Typography>
                      <Rating
                        value={testimonial.rating}
                        readOnly
                        sx={{ mb: 2 }}
                      />
                      <Typography
                        variant="body1"
                        color="textSecondary"
                      >
                        "{testimonial.comment}"
                      </Typography>
                    </CardContent>
                  </TestimonialCard>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        <Box sx={{ py: 8, background: 'background.default' }}>
          <Container maxWidth="lg">
            <Typography variant="h2" align="center" gutterBottom sx={{ mb: 6 }}>
              Como funciona
            </Typography>
            <Grid container spacing={4}>
              {process.map((step, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <ProcessStep elevation={0}>
                    <Box sx={{ mb: 2 }}>{step.icon}</Box>
                    <Typography variant="h6" gutterBottom>
                      {step.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {step.description}
                    </Typography>
                  </ProcessStep>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        <Box sx={{ py: 8, textAlign: 'center' }}>
          <Container maxWidth="sm">
            <Typography variant="h3" gutterBottom>
              Pronto para subir de ELO?
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              paragraph
              sx={{ mb: 4 }}
            >
              Junte-se a milhares de jogadores que já alcançaram seus objetivos com a ELOKING
            </Typography>
            <PrimaryButton
              component={RouterLink}
              to="/services"
              variant="contained"
              size="large"
            >
              Começar Agora
            </PrimaryButton>
          </Container>
        </Box>
      </Box>

      {/* Login Modal */}
      <StyledDialog open={openLogin} onClose={handleLoginClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h5">Login</Typography>
            <IconButton onClick={handleLoginClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <StyledTextField
              fullWidth
              label="Email"
              variant="outlined"
              InputProps={{
                startAdornment: <EmailIcon sx={{ mr: 1, color: 'text.secondary' }} />,
              }}
            />
            <StyledTextField
              fullWidth
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              InputProps={{
                startAdornment: <LockIcon sx={{ mr: 1, color: 'text.secondary' }} />,
                endAdornment: (
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                ),
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <GradientButton fullWidth onClick={handleLoginClose}>
            Entrar
          </GradientButton>
        </DialogActions>
        <Box sx={{ px: 3, pb: 3, textAlign: 'center' }}>
          <Typography variant="body2" color="textSecondary">
            Ainda não tem uma conta?{' '}
            <Button
              color="secondary"
              onClick={() => {
                handleLoginClose();
                handleRegisterOpen();
              }}
            >
              Registre-se
            </Button>
          </Typography>
        </Box>
      </StyledDialog>

      {/* Register Modal */}
      <StyledDialog open={openRegister} onClose={handleRegisterClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h5">Criar Conta</Typography>
            <IconButton onClick={handleRegisterClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <StyledTextField
              fullWidth
              label="Nome de usuário"
              variant="outlined"
              InputProps={{
                startAdornment: <PersonIcon sx={{ mr: 1, color: 'text.secondary' }} />,
              }}
            />
            <StyledTextField
              fullWidth
              label="Email"
              variant="outlined"
              InputProps={{
                startAdornment: <EmailIcon sx={{ mr: 1, color: 'text.secondary' }} />,
              }}
            />
            <StyledTextField
              fullWidth
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              InputProps={{
                startAdornment: <LockIcon sx={{ mr: 1, color: 'text.secondary' }} />,
                endAdornment: (
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                ),
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <GradientButton fullWidth onClick={handleRegisterClose}>
            Criar Conta
          </GradientButton>
        </DialogActions>
        <Box sx={{ px: 3, pb: 3, textAlign: 'center' }}>
          <Typography variant="body2" color="textSecondary">
            Já tem uma conta?{' '}
            <Button
              color="secondary"
              onClick={() => {
                handleRegisterClose();
                handleLoginOpen();
              }}
            >
              Faça login
            </Button>
          </Typography>
        </Box>
      </StyledDialog>
    </>
  );
};

export default Home; 