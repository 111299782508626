import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import styled from 'styled-components';

const DashboardLayoutRoot = styled(Box)`
  display: flex;
  min-height: 100vh;
  background: ${props => props.theme.palette.background.default};
`;

const DashboardLayout = () => {
  return (
    <DashboardLayoutRoot>
      <Outlet />
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout; 