import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  LinearProgress,
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  Snackbar,
  Alert,
} from '@mui/material';
import styled from 'styled-components';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AdminSidebar from '../../components/AdminSidebar';
import AddIcon from '@mui/icons-material/Add';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import CreateServiceModal from '../../components/CreateServiceModal';

const DashboardRoot = styled(Box)`
  display: flex;
  min-height: 100vh;
  background: ${props => props.theme.palette.background.default};
`;

const DashboardContent = styled(Box)`
  flex-grow: 1;
  padding: 24px;
  margin-left: 280px;
`;

const StatsCard = styled(Card)`
  background: linear-gradient(135deg, rgba(139, 92, 246, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
  border: 1px solid rgba(139, 92, 246, 0.2);
  height: 100%;
`;

const StyledCard = styled(Card)`
  background: linear-gradient(135deg, rgba(139, 92, 246, 0.1) 0%, rgba(139, 92, 246, 0.05) 100%);
  border: 1px solid rgba(139, 92, 246, 0.2);
  border-radius: 12px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(139, 92, 246, 0.2);
  }
`;

const NotificationDialog = styled(Dialog)`
  .MuiDialog-paper {
    background: #1a1625;
    border: 1px solid rgba(139, 92, 246, 0.2);
  }
`;

const Admin = () => {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [users, setUsers] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [stats, setStats] = useState({
    totalServices: 0,
    activeServices: 0,
    completedServices: 0,
  });

  const fetchData = async () => {
    try {
      // Buscar serviços
      const servicesSnapshot = await getDocs(collection(db, 'services'));
      const servicesData = servicesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setServices(servicesData);

      // Buscar usuários
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const usersData = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setUsers(usersData);

      // Calcular estatísticas
      const totalServices = servicesData.length;
      const activeServices = servicesData.filter(s => s.status === 'pending').length;
      const completedServices = servicesData.filter(s => s.status === 'completed').length;

      setStats({
        totalServices,
        activeServices,
        completedServices,
      });
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreateSuccess = () => {
    fetchData();
  };

  const handleSendNotification = async () => {
    try {
      if (selectedUsers.length === 0 || !notificationMessage) {
        setSnackbarMessage('Selecione pelo menos um usuário e digite uma mensagem');
        setSnackbarOpen(true);
        return;
      }

      // Criar um aviso para cada usuário selecionado
      const notifications = selectedUsers.map(user => ({
        userId: user.id,
        message: notificationMessage,
        createdAt: new Date(),
        read: false
      }));

      // Salvar todos os avisos no Firestore
      await Promise.all(
        notifications.map(notification =>
          addDoc(collection(db, 'notifications'), notification)
        )
      );

      setSnackbarMessage('Avisos enviados com sucesso!');
      setSnackbarOpen(true);
      setNotificationOpen(false);
      setNotificationMessage('');
      setSelectedUsers([]);
    } catch (error) {
      console.error('Erro ao enviar avisos:', error);
      setSnackbarMessage('Erro ao enviar avisos. Tente novamente.');
      setSnackbarOpen(true);
    }
  };

  return (
    <DashboardRoot>
      <AdminSidebar />
      <DashboardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Typography variant="h4" fontWeight="bold">
            Dashboard Administrativo
          </Typography>
          <Box display="flex" gap={2}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<NotificationsIcon />}
              onClick={() => setNotificationOpen(true)}
            >
              Enviar Avisos
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setOpenCreateModal(true)}
            >
              Criar Novo Serviço
            </Button>
          </Box>
        </Box>

        <Grid container spacing={4}>
          {/* Cards de Estatísticas */}
          <Grid item xs={12} sm={6} md={4}>
            <StatsCard>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <PeopleIcon sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
                  <Box>
                    <Typography variant="h4">{stats.totalServices}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Serviços Criados
                    </Typography>
                  </Box>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={75}
                  sx={{ height: 4, borderRadius: 2 }}
                />
              </CardContent>
            </StatsCard>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <StatsCard>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <AssignmentIcon sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
                  <Box>
                    <Typography variant="h4">{stats.activeServices}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Serviços Ativos
                    </Typography>
                  </Box>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={60}
                  sx={{ height: 4, borderRadius: 2 }}
                />
              </CardContent>
            </StatsCard>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <StatsCard>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <TrendingUpIcon sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
                  <Box>
                    <Typography variant="h4">{stats.completedServices}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Serviços Concluídos
                    </Typography>
                  </Box>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={85}
                  sx={{ height: 4, borderRadius: 2 }}
                />
              </CardContent>
            </StatsCard>
          </Grid>

          {/* Lista de Serviços */}
          <Grid item xs={12}>
            <StatsCard>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Serviços Disponíveis
                </Typography>
                <List>
                  {services.map((service, index) => (
                    <React.Fragment key={service.id}>
                      <ListItem>
                        <ListItemText
                          primary={service.name}
                          secondary={`${service.category} | ${service.duration} dias`}
                        />
                        <Typography variant="body2" color="primary">
                          R$ {service.price}
                        </Typography>
                      </ListItem>
                      {index < services.length - 1 && (
                        <Box sx={{ borderBottom: '1px solid rgba(139, 92, 246, 0.1)' }} />
                      )}
                    </React.Fragment>
                  ))}
                  {services.length === 0 && (
                    <ListItem>
                      <ListItemText
                        primary="Nenhum serviço cadastrado"
                        secondary="Clique em 'Criar Novo Serviço' para adicionar"
                      />
                    </ListItem>
                  )}
                </List>
              </CardContent>
            </StatsCard>
          </Grid>

          <Grid item xs={12}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Criar Novo Serviço
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  Adicione novos serviços ao catálogo com especificações detalhadas:
                </Typography>
                <ul style={{ paddingLeft: '20px' }}>
                  <li>
                    <Typography variant="body2" color="textSecondary">
                      Nome e descrição do serviço
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2" color="textSecondary">
                      Preço e duração estimada
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2" color="textSecondary">
                      Requisitos e informações adicionais
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2" color="textSecondary">
                      Categorias e tags relacionadas
                    </Typography>
                  </li>
                </ul>
                <Box mt={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpenCreateModal(true)}
                  >
                    Começar a Criar
                  </Button>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>

        <NotificationDialog
          open={notificationOpen}
          onClose={() => setNotificationOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            Enviar Avisos aos Usuários
          </DialogTitle>
          <DialogContent>
            <Box mb={3}>
              <Autocomplete
                multiple
                options={users}
                getOptionLabel={(option) => option.email}
                value={selectedUsers}
                onChange={(event, newValue) => setSelectedUsers(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecionar Usuários"
                    placeholder="Buscar usuários..."
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        background: 'rgba(255, 255, 255, 0.05)'
                      }
                    }}
                  />
                )}
              />
            </Box>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={notificationMessage}
              onChange={(e) => setNotificationMessage(e.target.value)}
              placeholder="Digite sua mensagem..."
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  background: 'rgba(255, 255, 255, 0.05)'
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setNotificationOpen(false)}>
              Cancelar
            </Button>
            <Button
              onClick={handleSendNotification}
              variant="contained"
              color="primary"
              disabled={selectedUsers.length === 0 || !notificationMessage}
            >
              Enviar
            </Button>
          </DialogActions>
        </NotificationDialog>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarMessage.includes('Erro') ? 'error' : 'success'}
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <CreateServiceModal
          open={openCreateModal}
          onClose={() => setOpenCreateModal(false)}
          onSuccess={handleCreateSuccess}
        />
      </DashboardContent>
    </DashboardRoot>
  );
};

export default Admin; 