import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import LogoutIcon from '@mui/icons-material/Logout';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 280px;
    background: linear-gradient(180deg, #1a1a1a 0%, #2d2d2d 100%);
    border-right: 1px solid rgba(139, 92, 246, 0.2);
    padding: 24px 16px;
  }
`;

const AdminHeader = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 0;
  margin-bottom: 24px;
`;

const MenuList = styled(List)`
  margin-top: 24px;
`;

const MenuItem = styled(ListItem)`
  border-radius: 8px;
  margin-bottom: 8px;
  
  &:hover {
    background: rgba(139, 92, 246, 0.1);
  }
  
  &.active {
    background: rgba(139, 92, 246, 0.2);
  }
`;

const AdminSidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/');
  };

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/admin' },
    { text: 'Clientes', icon: <PeopleIcon />, path: '/admin/customers' },
    { text: 'Serviços', icon: <RocketLaunchIcon />, path: '/admin/services' },
  ];

  return (
    <StyledDrawer variant="permanent">
      <AdminHeader>
        <AdminPanelSettingsIcon sx={{ fontSize: 32, color: 'primary.main' }} />
        <Box>
          <Typography variant="h6">Admin Panel</Typography>
          <Typography variant="body2" color="textSecondary">
            Painel Administrativo
          </Typography>
        </Box>
      </AdminHeader>

      <Divider sx={{ borderColor: 'rgba(139, 92, 246, 0.2)' }} />

      <MenuList>
        {menuItems.map((item) => (
          <MenuItem
            button
            key={item.text}
            onClick={() => navigate(item.path)}
          >
            <ListItemIcon sx={{ color: 'primary.main' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </MenuItem>
        ))}
      </MenuList>

      <Box sx={{ flexGrow: 1 }} />

      <MenuItem button onClick={handleLogout} sx={{ mt: 2 }}>
        <ListItemIcon sx={{ color: 'error.main' }}>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Sair" />
      </MenuItem>
    </StyledDrawer>
  );
};

export default AdminSidebar; 