import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Grid,
  Alert,
  Autocomplete,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background: linear-gradient(135deg, rgba(139, 92, 246, 0.1) 0%, rgba(139, 92, 246, 0.05) 100%);
    border: 1px solid rgba(139, 92, 246, 0.2);
    backdrop-filter: blur(10px);
  }
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 16px;
  
  .MuiOutlinedInput-root {
    background: rgba(255, 255, 255, 0.05);
    
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(139, 92, 246, 0.5);
      }
    }
    
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(139, 92, 246, 1);
      }
    }
  }
`;

const StyledSelect = styled(Select)`
  margin-bottom: 16px;
  
  .MuiOutlinedInput-root {
    background: rgba(255, 255, 255, 0.05);
  }
`;

const RiotIdInput = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: flex-start;
  margin-bottom: 16px;

  .name-field {
    flex: 1;
  }

  .tag-field {
    width: 120px;
  }
`;

const CreateServiceModal = ({ open, onClose, onSuccess }) => {
  const [formData, setFormData] = useState({
    gameName: '',
    tagLine: '',
    description: '',
    price: '',
    duration: '',
    category: '',
    assignedTo: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, 'users'));
        const usersData = usersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setUsers(usersData);
      } catch (error) {
        console.error('Erro ao buscar usuários:', error);
      }
    };

    if (open) {
      fetchUsers();
    }
  }, [open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'gameName') {
      // Remove caracteres especiais e espaços do nome
      const formattedValue = value.replace(/[^a-zA-Z0-9]/g, '');
      setFormData(prev => ({
        ...prev,
        [name]: formattedValue
      }));
    } else if (name === 'tagLine') {
      // Remove caracteres especiais e espaços da tag
      const formattedValue = value.replace(/[^a-zA-Z0-9]/g, '');
      setFormData(prev => ({
        ...prev,
        [name]: formattedValue
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    setError('');
  };

  const handleUserChange = (event, newValue) => {
    setFormData(prev => ({
      ...prev,
      assignedTo: newValue
    }));
  };

  const validateRiotId = (gameName, tagLine) => {
    // Verifica se o nome tem entre 3 e 16 caracteres
    if (gameName.length < 3 || gameName.length > 16) {
      return false;
    }
    // Verifica se a tag tem entre 3 e 5 caracteres
    if (tagLine.length < 3 || tagLine.length > 5) {
      return false;
    }
    // Verifica se contém apenas letras e números
    const pattern = /^[a-zA-Z0-9]+$/;
    return pattern.test(gameName) && pattern.test(tagLine);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      // Validações
      if (!formData.gameName || !formData.tagLine || !formData.description || !formData.price || !formData.duration || !formData.category) {
        throw new Error('Por favor, preencha todos os campos obrigatórios');
      }

      if (!validateRiotId(formData.gameName, formData.tagLine)) {
        throw new Error('Riot ID inválido. O nome deve ter entre 3 e 16 caracteres e a tag entre 3 e 5 caracteres, usando apenas letras e números.');
      }

      // Criar serviço no Firestore
      const docRef = await addDoc(collection(db, 'services'), {
        name: `${formData.gameName}#${formData.tagLine}`,
        description: formData.description,
        price: Number(formData.price),
        duration: Number(formData.duration),
        assignedTo: formData.assignedTo ? {
          id: formData.assignedTo.id,
          email: formData.assignedTo.email,
        } : null,
        category: formData.category,
        createdAt: new Date(),
        updatedAt: new Date(),
        status: 'pending',
        progress: 0,
      });

      onSuccess?.();
      onClose();
    } catch (error) {
      console.error('Erro ao criar serviço:', error);
      setError(error.message || 'Ocorreu um erro ao criar o serviço');
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" fontWeight="bold">
            Criar Novo Serviço
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                Riot ID
              </Typography>
              <RiotIdInput>
                <StyledTextField
                  className="name-field"
                  label="Nome do Jogador"
                  name="gameName"
                  value={formData.gameName}
                  onChange={handleChange}
                  required
                  placeholder="whyimalive"
                  helperText="3-16 caracteres, apenas letras e números"
                />
                <StyledTextField
                  className="tag-field"
                  label="Tag"
                  name="tagLine"
                  value={formData.tagLine}
                  onChange={handleChange}
                  required
                  placeholder="bob1k"
                  helperText="3-5 caracteres"
                />
              </RiotIdInput>
            </Grid>

            <Grid item xs={12}>
              <StyledTextField
                fullWidth
                label="Descrição"
                name="description"
                value={formData.description}
                onChange={handleChange}
                multiline
                rows={4}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                label="Preço"
                name="price"
                type="number"
                value={formData.price}
                onChange={handleChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                }}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                label="Duração Estimada (em dias)"
                name="duration"
                type="number"
                value={formData.duration}
                onChange={handleChange}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Categoria</InputLabel>
                <StyledSelect
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  required
                >
                  <MenuItem value="boost">Boost</MenuItem>
                  <MenuItem value="coaching">Coaching</MenuItem>
                  <MenuItem value="duo">Duo</MenuItem>
                  <MenuItem value="outros">Outros</MenuItem>
                </StyledSelect>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                options={users}
                getOptionLabel={(option) => option.email}
                value={formData.assignedTo}
                onChange={handleUserChange}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    label="Atribuir a Usuário"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ p: 3 }}>
          <Button
            variant="outlined"
            onClick={onClose}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={loading}
          >
            {loading ? 'Criando...' : 'Criar Serviço'}
          </Button>
        </DialogActions>
      </form>
    </StyledDialog>
  );
};

export default CreateServiceModal; 