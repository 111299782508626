import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const PrivateRoute = ({ children, requiredRole }) => {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <div>Carregando...</div>;
  }

  // Se não estiver autenticado, redireciona para login
  if (!user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // Se requiredRole for especificado, verifica se o usuário tem a role necessária
  if (requiredRole && user.role !== requiredRole) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

export default PrivateRoute; 