import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { 
  getAuth, 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged 
} from "firebase/auth";
import { 
  getFirestore, 
  collection, 
  doc, 
  getDoc, 
  getDocs,
  setDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  limit
} from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD18e2BBDwG3qD9cldLzjuyWObAn5Mkyl8",
  authDomain: "hexshade-67d2a.firebaseapp.com",
  projectId: "hexshade-67d2a",
  storageBucket: "hexshade-67d2a.firebasestorage.app",
  messagingSenderId: "785954474402",
  appId: "1:785954474402:web:00793d8a1ca2cce8a58afb",
  measurementId: "G-PJWR5YJ6P8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Roles disponíveis
export const ROLES = {
  ADMIN: 'admin',
  USER: 'user'
};

// Serviços de Autenticação
export const loginWithEmail = (email, password) => 
  signInWithEmailAndPassword(auth, email, password);

export const registerWithEmail = async (email, password, role = ROLES.USER) => {
  // Criar usuário no Firebase Auth
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  
  // Criar documento do usuário no Firestore
  await setDoc(doc(db, 'users', userCredential.user.uid), {
    email,
    role,
    createdAt: new Date(),
    updatedAt: new Date()
  });

  return { user: userCredential.user };
};

export const createAdminUser = async (email, password) => {
  return registerWithEmail(email, password, ROLES.ADMIN);
};

export const setUserRole = async (userId, role) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    role,
    updatedAt: new Date()
  });
};

export const getUserRole = async (userId) => {
  const userDoc = await getDocument('users', userId);
  return userDoc?.role || null;
};

export const logout = () => signOut(auth);

export const onAuthChange = (callback) => 
  onAuthStateChanged(auth, callback);

// Serviços do Firestore
export const createDocument = async (collectionName, data, id = null) => {
  const collectionRef = collection(db, collectionName);
  if (id) {
    await setDoc(doc(collectionRef, id), {
      ...data,
      createdAt: new Date(),
      updatedAt: new Date()
    });
    return id;
  } else {
    const docRef = await addDoc(collectionRef, {
      ...data,
      createdAt: new Date(),
      updatedAt: new Date()
    });
    return docRef.id;
  }
};

export const updateDocument = async (collectionName, id, data) => {
  const docRef = doc(db, collectionName, id);
  await updateDoc(docRef, {
    ...data,
    updatedAt: new Date()
  });
};

export const deleteDocument = async (collectionName, id) => {
  const docRef = doc(db, collectionName, id);
  await deleteDoc(docRef);
};

export const getDocument = async (collectionName, id) => {
  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);
  return docSnap.exists() ? { id: docSnap.id, ...docSnap.data() } : null;
};

export const getCollection = async (collectionName, conditions = []) => {
  const collectionRef = collection(db, collectionName);
  let q = collectionRef;

  if (conditions.length > 0) {
    q = query(collectionRef, ...conditions);
  }

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
};

// Helpers para queries
export const whereEqual = (field, value) => where(field, "==", value);
export const orderByDesc = (field) => orderBy(field, "desc");
export const orderByAsc = (field) => orderBy(field, "asc");
export const limitTo = (number) => limit(number);

export const getUserData = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      return { id: userDoc.id, ...userDoc.data() };
    }
    return null;
  } catch (error) {
    console.error('Erro ao buscar dados do usuário:', error);
    return null;
  }
};

export { app, auth, db, storage, analytics }; 