import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './styles/theme';
import GlobalStyles from './styles/GlobalStyles';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import Pricing from './pages/Pricing';
import Dashboard from './pages/Dashboard';
import Admin from './pages/Admin';
import HowItWorks from './pages/HowItWorks';
import DashboardLayout from './layouts/DashboardLayout';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import CreateService from './pages/Admin/CreateService';
import ServicesAdmin from './pages/Admin/Services';
import Customers from './pages/Admin/Customers';

const App = () => {
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);

  const handleLoginOpen = () => {
    setOpenLogin(true);
    setOpenRegister(false);
  };

  const handleLoginClose = () => {
    setOpenLogin(false);
  };

  const handleRegisterOpen = () => {
    setOpenRegister(true);
    setOpenLogin(false);
  };

  const handleRegisterClose = () => {
    setOpenRegister(false);
  };

  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <AuthProvider>
            <CssBaseline />
            <GlobalStyles />
            <Routes>
              {/* Rotas públicas com Header e Footer */}
              <Route
                element={
                  <div className="gradient-bg" style={{ 
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                    <Header
                      onLoginClick={handleLoginOpen}
                      onRegisterClick={handleRegisterOpen}
                    />
                    <main style={{ flex: 1 }}>
                      <Outlet />
                    </main>
                    <Footer />
                  </div>
                }
              >
                <Route
                  path="/"
                  element={
                    <Home
                      openLogin={openLogin}
                      openRegister={openRegister}
                      onLoginClose={handleLoginClose}
                      onRegisterClose={handleRegisterClose}
                      onLoginClick={handleLoginOpen}
                      onRegisterClick={handleRegisterOpen}
                    />
                  }
                />
                <Route path="/services" element={<Services />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/how-it-works" element={<HowItWorks />} />
              </Route>

              {/* Rotas protegidas sem Header e Footer */}
              <Route element={<DashboardLayout />}>
                {/* Rota do Dashboard (requer autenticação) */}
                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />

                {/* Rotas do Admin (requer role de admin) */}
                <Route
                  path="/admin"
                  element={
                    <PrivateRoute requiredRole="admin">
                      <Admin />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin/services"
                  element={
                    <PrivateRoute requiredRole="admin">
                      <ServicesAdmin />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin/services/new"
                  element={
                    <PrivateRoute requiredRole="admin">
                      <CreateService />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin/customers"
                  element={
                    <PrivateRoute requiredRole="admin">
                      <Customers />
                    </PrivateRoute>
                  }
                />
              </Route>
            </Routes>
          </AuthProvider>
        </StyledThemeProvider>
      </MuiThemeProvider>
    </Router>
  );
};

export default App; 